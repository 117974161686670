import React, { Component } from "react";

import "../estilos/checkinbox.css";

class Checkinbox extends Component {
  render = () => {
    return (
      <div id="container">
        <div className="toggle">
          <input
            type="checkbox"
            name="toggle"
            className="check-checkbox"
            id="mytoggle"
            checked={this.props.boleano}
            onChange={() => {}}
          />
          <label className="check-label" htmlFor="mytoggle">
            <div id="background"></div>
            <span className="face">
              <span className="face-container">
                <span className="eye left"></span>
                <span className="eye right"></span>
                <span className="mouth"></span>
              </span>
            </span>
          </label>
        </div>
      </div>
    );
  };
}
export default Checkinbox;
