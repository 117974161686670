import React, { Component } from "react";
import TextareaAutosize from "react-textarea-autosize";

import axios from "axios";

import Editusuario from "./editusuario";
import Editcoti from "./editcoti";
import Checkinbox from "./checkinbox";

class Elperfil extends Component {
  state = {
    yosoy: [],
    alone: false,
    segunlone: false,
    coti: [],
    elcontenresp: [],
    contrat: [],
    tipoboton: 1,
    actico: -1,
    acticon: -1,
    panta: 1,
    pagpro: -1,
    pagasis: -1,
    enviados: [],
    sol: true,
    extamensa: "",
    todocont: [],
    mensaje: "",
    valuecitos: [],
    terminos: [],
    proces: [],
    actboproc: [-1, -1],
    ventacotprend: false,
    ventamesa: false,
    texchat: [],
    enviatexto: "",
    carga: false,
    apipagos: [],
    acttipago: false,
    actiyapag: false,
    establedialogo: 0,
  };

  agregarcotizas = () => {
    let lista = [];
    for (let i = 0; i < this.state.coti.length; i++) {
      var elco = this.state.elcontenresp[i];
      if (elco !== undefined && elco !== null) {
        var contenv = "";
        if (this.state.coti[i].enviad === 1) {
          contenv = this.props.eltexto(50);
        }
        elco = elco[1];
        lista.push(
          <div
            className="contenidoperfil mandamelade"
            key={i}
            onClick={() => {
              this.activaco(i, this.state.actico, "actico");
            }}
          >
            <Checkinbox boleano={this.clasinteract(i, this.state.actico)} />

            <div className="textoexpli">
              {elco[this.props.langua]} : {this.state.coti[i].fecha} {contenv}
            </div>
            <div className="tapatodo"></div>
          </div>
        );
      }
    }
    return lista;
  };

  agregarcoctra = () => {
    let lista = [];
    for (let i = 0; i < this.state.contrat.length; i++) {
      lista.push(
        <div
          className="contenidoperfil mandamelade"
          key={i}
          onClick={() => {
            this.activaco(i, this.state.acticon, "acticon");
          }}
        >
          <Checkinbox boleano={this.clasinteract(i, this.state.acticon)} />
          <div className="textoexpli">{this.state.contrat[i].name}</div>
          <div className="tapatodo"></div>
        </div>
      );
    }
    return lista;
  };

  queresp = () => {
    let content = [];
    for (let i = 0; i < this.state.coti.length; i++) {
      var envi = this.state.coti[i];
      envi.lang = this.props.langua;
      axios
        .post("https://api.desenfrenadonetworks.com/perfilus/acabepre", envi)
        .then((response) => {
          content[i] = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    this.setState({ elcontenresp: content });
  };
  activaco = (iden, stado, strstado) => {
    if (stado === iden) {
      this.setState({ actico: -1, tipoboton: 1, acticon: -1 });
    } else if (strstado === "actico") {
      this.setState({ actico: iden, tipoboton: 2, acticon: -1 });
    } else if (strstado === "acticon") {
      this.setState({ actico: -1, tipoboton: 3, acticon: iden });
    } else {
      this.setState({ actico: -1, tipoboton: 1, acticon: -1 });
    }
  };
  clasinteract = (iden, elstad) => {
    if (elstad === iden) {
      return true;
    } else {
      return false;
    }
  };

  verificacota = () => {
    if (this.state.yosoy.cot === "") {
      return null;
    } else {
      if (this.state.alone) {
        var lascoti = { iden: this.state.yosoy.cot };
        axios
          .post("https://api.desenfrenadonetworks.com/perfilus/coti", lascoti)
          .then((response) => {
            this.setState({ alone: false, coti: response.data });
            this.queresp();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      return (
        <div className="elrecdelacot">
          <div className="textotimipe conboton">
            <div>
              <i className="far fa-caret-square-down"></i>{" "}
              {this.props.eltexto(43)}
            </div>
            {this.veriboton(2)}
          </div>

          {this.agregarcotizas()}
        </div>
      );
    }
  };
  verificacont = () => {
    if (this.state.yosoy.cont === "") {
      return null;
    } else {
      if (this.state.segunlone) {
        var lascoti = {
          iden: this.state.yosoy.cont,
          dequien: this.props.loguea,
          eldio: this.props.langua,
        };
        axios
          .post("https://api.desenfrenadonetworks.com/contra", lascoti)
          .then((response) => {
            this.setState({ segunlone: false, contrat: response.data });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      return (
        <div className="elrecdelacota">
          <div className="textotimipe conboton lineasupe">
            <div>
              <i className="far fa-caret-square-down"></i>{" "}
              {this.props.eltexto(44)}
            </div>
            {this.veriboton(3)}
          </div>
          {this.agregarcoctra()}
        </div>
      );
    }
  };

  apagueyvamonos = () => {
    localStorage.setItem("userio", "soyinvitado");
    localStorage.setItem("idea", "soyinvitado");
    localStorage.setItem("name", "soyinvitado");
    this.props.cierra("soyinvitado", "soyinvitado", "soyinvitado");
    window.location.replace("");
  };

  traedatos = () => {
    axios
      .post("https://api.desenfrenadonetworks.com/perfilus", this.props.loguea)
      .then((response) => {
        this.setState({
          yosoy: response.data[0],
          alone: true,
          segunlone: true,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  editusuario = (val) => {
    this.setState({ panta: val });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChanges = (e) => {
    var valor = e.target.value.replace(/\n/g, " ");
    this.setState({ [e.target.name]: valor });
  };

  veriboton = (val, miconos) => {
    if (val === 1) {
      return (
        <div className="vanlosiconos">
          <div className="iconopuntual" onClick={() => this.editusuario(2)}>
            <i className="far fa-user-edit"></i>
            <div className="textoicono">{this.props.eltexto(45)}</div>
          </div>
          <div className="iconopuntual" onClick={this.apagueyvamonos}>
            <i className="far fa-sign-out-alt"></i>
            <div className="textoicono">{this.props.eltexto(48)}</div>
          </div>
        </div>
      );
    } else if (this.state.tipoboton === 2 && val === 2) {
      return (
        <div className="vanlosiconos">
          <div className="iconopuntual" onClick={() => this.editusuario(5)}>
            <i className="far fa-edit"></i>
            <div className="textoicono">{this.props.eltexto(47)}</div>
          </div>
          <div className="iconopuntual" onClick={() => this.editusuario(4)}>
            <i className="far fa-share"></i>
            <div className="textoicono">{this.props.eltexto(49)}</div>
          </div>
          <div className="iconopuntual" onClick={() => this.editusuario(3)}>
            <i className="far fa-trash-alt"></i>
            <div className="textoicono">{this.props.eltexto(46)}</div>
          </div>
        </div>
      );
    } else if (this.state.tipoboton === 3 && val === 3) {
      return (
        <div className="vanlosiconos">
          <div
            className="iconopuntual"
            onClick={() => {
              this.editusuario(6);
              this.activaloscot();
            }}
          >
            <i className="far fa-file-contract"></i>
            <div className="textoicono">{this.props.eltexto(145)}</div>
          </div>
          <div
            className="iconopuntual"
            onClick={() => {
              this.editusuario(7);
              this.activapa();
            }}
          >
            <i className="far fa-money-bill-alt"></i>
            <div className="textoicono">{this.props.eltexto(146)}</div>
          </div>
          <div
            className="iconopuntual"
            onClick={() => {
              this.editusuario(8);
              this.activalosproce();
            }}
          >
            <i className="far fa-tasks"></i>
            <div className="textoicono">{this.props.eltexto(147)}</div>
          </div>
        </div>
      );
    } else if (val === 4 && miconos === this.state.actboproc[0]) {
      return (
        <div className="vanlosiconos">
          <div
            className="iconopuntual"
            onClick={() => {
              this.muestralapanta(true);
              window.open(
                this.state.proces[this.state.actboproc[0]].entregas[
                  this.state.actboproc[1]
                ].ubicarchivo
              );
            }}
          >
            <i className="far fa-eye"></i>
            <div className="textoicono">{this.props.eltexto(150)}</div>
          </div>
          <div
            className="iconopuntual"
            onClick={() => {
              this.activetamesa(true);
              this.traetextomensajes();
            }}
          >
            <i className="far fa-comment-dots"></i>
            <div className="textoicono">{this.props.eltexto(151)}</div>
          </div>
        </div>
      );
    } else if (val === 5) {
      if (miconos === 0 && this.state.pagpro !== -1) {
        return (
          <div className="vanlosiconos">
            <div
              className="iconopuntual"
              onClick={() => {
                this.abremepago(true);
              }}
            >
              <i className="far fa-donate"></i>
              <div className="textoicono">{this.props.eltexto(76)}</div>
            </div>
          </div>
        );
      } else if (miconos === 1 && this.state.pagasis !== -1) {
        return (
          <div className="vanlosiconos">
            <div
              className="iconopuntual"
              onClick={() => {
                this.abremepago(true);
              }}
            >
              <i className="far fa-donate"></i>
              <div className="textoicono">{this.props.eltexto(76)}</div>
            </div>
          </div>
        );
      }
    } else if (val === 6) {
      return (
        <div className="iconosconfirm">
          <div className="iconopuntual" onClick={() => this.editusuario(1)}>
            <i className="far fa-window-close"></i>
            <div className="textoicono">{this.props.eltexto(54)}</div>
          </div>
          <a
            className="iconopuntual"
            href={this.elink(0)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="far fa-file-alt"></i>
            <div className="textoicono">{this.props.eltexto(148)}</div>
          </a>
          <a
            className="iconopuntual"
            href={this.elink(1)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="far fa-file-alt"></i>
            <div className="textoicono">{this.props.eltexto(149)}</div>
          </a>
        </div>
      );
    } else if (val === 7) {
      return (
        <div className="iconosconfirm">
          <div className="iconopuntual" onClick={() => this.editusuario(1)}>
            <i className="far fa-window-close"></i>
            <div className="textoicono">{this.props.eltexto(54)}</div>
          </div>
          <div className="iconopuntual" onClick={() => this.envedit(0)}>
            <i className="far fa-save"></i>
            <div className="textoicono">{this.props.eltexto(51)}</div>
          </div>
          <div className="iconopuntual" onClick={() => this.envedit(1)}>
            <i className="far fa-check-square"></i>
            <div className="textoicono">{this.props.eltexto(49)}</div>
          </div>
        </div>
      );
    } else if (val === 8) {
      return (
        <div className="iconosconfirm">
          <div className="iconopuntual" onClick={() => this.editusuario(1)}>
            <i className="far fa-window-close"></i>
            <div className="textoicono">{this.props.eltexto(54)}</div>
          </div>
          <div className="iconopuntual" onClick={this.cuandosuba}>
            <i className="far fa-check-square"></i>
            <div className="textoicono">{this.props.eltexto(49)}</div>
          </div>
        </div>
      );
    } else if (val === 9) {
      return (
        <div className="iconosconfirm">
          <div className="iconopuntual" onClick={() => this.editusuario(1)}>
            <i className="far fa-window-close"></i>
            <div className="textoicono">{this.props.eltexto(54)}</div>
          </div>
          <div className="iconopuntual" onClick={this.borrarcoti}>
            <i className="far fa-check-square"></i>
            <div className="textoicono">{this.props.eltexto(46)}</div>
          </div>
        </div>
      );
    }
  };
  activetamesa = (valor) => {
    this.setState({ ventamesa: valor });
  };

  borrarcoti = (val) => {
    var envi = {
      ident: this.props.loguea,
      valor: this.state.coti[this.state.actico].ID,
    };
    axios
      .post("https://api.desenfrenadonetworks.com/perfilus/borrarco", envi)
      .then((response) => {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  cuandosuba = () => {
    var envi = {
      ident: this.props.loguea,
      valor: this.state.coti[this.state.actico].ID,
      mensaje: this.state.extamensa,
    };
    axios
      .post("https://api.desenfrenadonetworks.com/perfilus/envco", envi)
      .then((response) => {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  traestates = (da1, da2) => {
    this.setState({ mensaje: da1, valuecitos: da2 });
  };
  envedit = (val) => {
    if (this.state.valuecitos.length === 0 && val === 0) {
      this.editusuario(1);
    } else if (val === 1 && this.state.valuecitos.length === 0) {
      this.cuandosuba();
    } else {
      var envi = {
        ident: this.props.loguea,
        valor: this.state.coti[this.state.actico].ID,
        mensaje: this.state.mensaje,
        aguar: String(this.state.valuecitos),
        env: val,
      };
      axios
        .post("https://api.desenfrenadonetworks.com/perfilus/guardad", envi)
        .then((response) => {
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  activaloscot = () => {
    var lascoti = {
      cual: this.state.contrat[this.state.acticon].terminos,
      eldio: this.props.langua,
      dequien: this.props.loguea,
      elcom: this.state.contrat[this.state.acticon].ID,
    };
    axios
      .post("https://api.desenfrenadonetworks.com/contra/letra", lascoti)
      .then((response) => {
        this.setState({ terminos: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  activapa = () => {
    var lascoti = {
      dequien: this.props.loguea,
      elcom: this.state.contrat[this.state.acticon].ID,
    };
    axios
      .post("https://api.desenfrenadonetworks.com/contra/pagos", lascoti)
      .then((response) => {
        this.setState({ apipagos: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  activalosproce = () => {
    var lascoti = {
      eldio: this.props.langua,
      dequien: this.props.loguea,
      elcom: this.state.contrat[this.state.acticon].ID,
    };
    axios
      .post("https://api.desenfrenadonetworks.com/contra/proces", lascoti)
      .then((response) => {
        this.setState({ proces: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  envmensaje = () => {
    this.setState({ carga: true });
    var eleme = this.state.enviatexto.replace(/ /g, "");
    if (eleme !== "") {
      var lascoti = {
        elmen: this.state.enviatexto,
        dequien: this.props.loguea,
        aquien: this.state.proces[this.state.actboproc[0]].entregas[
          this.state.actboproc[1]
        ].ID,
        lacot: this.state.contrat[this.state.acticon].ID,
      };
      axios
        .post("https://api.desenfrenadonetworks.com/contra/envmensaje", lascoti)
        .then((response) => {
          this.activalosproce();
          setTimeout(() => {
            this.traetextomensajes();
            this.setState({ enviatexto: "", carga: false });
          }, 4000);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  vericontra = () => {
    var resp = ["loading..."];
    if (
      this.state.terminos.textos !== undefined &&
      this.state.terminos.textos !== null
    ) {
      resp = [];
      for (var i = 0; i < this.state.terminos.textos.length; i++) {
        resp.push(this.state.terminos.textos[i]);
        resp.push(<br key={"salto" + i} />);
      }
    }
    return resp;
  };
  elink = (vale) => {
    if (this.state.terminos.links === undefined) {
    } else {
      return this.state.terminos.links[vale];
    }
  };
  traeproces = () => {
    var regres = ["loading..."];
    if (this.state.proces.length > 0) {
      regres = [];
      for (var i = 0; i < this.state.proces.length; i++) {
        regres.push(
          <div className="textotimipe conboton alaiz" key={"fech" + i}>
            <div>
              <i className="far fa-caret-square-down"></i>{" "}
              {this.state.proces[i].fecha[0].fecha}{" "}
              {this.state.proces[i].fecha[0].objetivo}
            </div>
            {this.veriboton(4, i)}
          </div>
        );
        var segunrray = [];
        for (var j = 0; j < this.state.proces[i].entregas.length; j++) {
          segunrray.push(this.eldelosclicks(i, j));
        }
        regres.push(
          <div className=" ajustatext agranpoco" key={"result" + i}>
            {segunrray}
          </div>
        );
      }
    }

    return regres;
  };

  determinactivo = (laubic) => {
    if (this.state.actboproc.toString() === laubic.toString()) {
      return true;
    } else {
      return false;
    }
  };

  eldelosclicks = (aruno, ardos) => {
    return (
      <div
        className="listadeentr verla"
        onClick={() => this.clickcheentre([aruno, ardos])}
        key={"botons" + aruno + ardos}
      >
        <Checkinbox boleano={this.determinactivo([aruno, ardos])} />{" "}
        <div className="pequemar">
          {this.state.proces[aruno].entregas[ardos].fecha}{" "}
          {this.state.proces[aruno].entregas[ardos].name}
        </div>
        <div className="tapatodo"></div>
      </div>
    );
  };

  clickcheentre = (group) => {
    if (this.state.actboproc.toString() === group.toString()) {
      this.setState({ actboproc: [-1, -1] });
    } else {
      this.setState({ actboproc: group });
    }
  };
  eye = () => {
    return {
      top: this.state.actiyapag ? "0" : "130%",
    };
  };
  ele = () => {
    return {
      top: this.state.ventacotprend ? "0" : "130%",
    };
  };
  ene = () => {
    return {
      top: this.state.acttipago ? "0" : "130%",
    };
  };
  eme = () => {
    return {
      top: this.state.ventamesa ? "0" : "130%",
    };
  };
  ese = () => {
    return {
      bottom: this.state.ventamesa ? null : "-200vh",
    };
  };
  eve = () => {
    if (this.state.carga) {
      return "far fa-spinner iconoenviar giragira";
    } else {
      return "far fa-paper-plane iconoenviar";
    }
  };

  abremepago = (vale) => {
    this.setState({ acttipago: vale });
  };
  actpantayapaga = (datus) => {
    this.setState({ actiyapag: datus });
  };

  muestralapanta = (datus) => {
    this.setState({ ventacotprend: datus });
  };
  traetextomensajes = () => {
    var clien = [];
    if (this.state.actboproc[0] !== -1) {
      var sring = this.state.proces[this.state.actboproc[0]].entregas[
        this.state.actboproc[1]
      ].acomentusu;
      clien = sring.split("$");
      if (clien[0] !== "") {
        for (var i = 0; i < clien.length; i++) {
          clien[i] = JSON.parse(clien[i]);
        }
      }

      this.setState({ texchat: [clien] });
      setTimeout(() => {
        var objDiv = document.getElementsByClassName("conchat");
        objDiv[0].scrollTop = objDiv[0].scrollHeight;
      }, 500);
    }
  };

  componentDidMount = () => {
    this.traedatos();
  };
  imprimechat = () => {
    var tiempo = "";
    var total = [];
    if (
      this.state.texchat[0] !== undefined &&
      this.state.texchat[0][0] !== ""
    ) {
      var laclas = "";
      for (var i = 0; i < this.state.texchat[0].length; i++) {
        if (
          i === 0 ||
          this.state.texchat[0][i].date !== this.state.texchat[0][i - 1].date
        ) {
          total.push(
            <div className="day" key={"time" + i}>
              {this.state.texchat[0][i].date}
            </div>
          );
        }
        if (this.state.texchat[0][i].l === "b") {
          laclas = "other";
        } else {
          laclas = "self";
        }
        tiempo = this.state.texchat[0][i].time.split(":");

        total.push(
          <div
            className={laclas}
            key={"nous" + i}
            id={this.mandalid(this.state.texchat[0].length, i)}
          >
            <div className="msg">
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.texchat[0][i].mens,
                }}
              ></p>
              <time>{tiempo[0] + ":" + tiempo[1]}</time>
            </div>
          </div>
        );
      }
    }
    return total;
  };
  mandalid = (elv, seg) => {
    if (elv - 1 === seg) {
      return "u";
    }
  };

  imprimelista = (ubic, elobj) => {
    var pagado = "";
    var fechapa = "";
    var lalin = "";
    var elsta = "";
    if (elobj === this.state.apipagos[0]) {
      elsta = "pagpro";
    } else {
      elsta = "pagasis";
    }
    if (elobj[ubic].estad === 1) {
      pagado = this.props.eltexto(156);
      fechapa = elobj[ubic].fechapag;
      lalin = "lallineaand";
    }
    return (
      <div className="listadeentr verla" key={ubic}>
        <Checkinbox boleano={this.regresabol(elsta, ubic, elobj[ubic].estad)} />
        <div className="pequemar">
          {" "}
          <div className={lalin}>
            {elobj[ubic].fechalim} {elobj[ubic].nombre}
          </div>
          <div>
            {pagado} {fechapa}
          </div>
        </div>
        <div
          className="tapatodo"
          onClick={() => {
            this.prendeinterri(elsta, elobj[ubic].estad, ubic);
          }}
        ></div>
      </div>
    );
  };
  prendeinterri = (est, obe, ubi) => {
    var elotro = "";
    if (est === "pagpro") {
      elotro = "pagasis";
    } else {
      elotro = "pagpro";
    }
    if (this.state[est] !== ubi) {
      if (obe === 0) {
        this.setState({ [est]: ubi, [elotro]: -1 });
      }
    } else {
      this.setState({ [est]: -1, [elotro]: -1 });
    }
  };

  regresabol = (elnom, laub, elcom) => {
    if (elcom !== 1) {
      if (this.state[elnom] === laub) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  cargaproyect = (dat) => {
    if (this.state.apipagos[dat] !== undefined) {
      if (this.state.apipagos[dat].length !== 0) {
        let regresa = [];
        regresa.push(
          <div className="textotimipe conboton alaiz" key={"a" + 1}>
            <div>
              <i className="far fa-caret-square-down"></i>{" "}
              {this.props.eltexto(154 + dat)}{" "}
            </div>
            {this.veriboton(5, dat)}
          </div>
        );
        var segunaray = [];
        for (var i = 0; i < this.state.apipagos[dat].length; i++) {
          segunaray.push(this.imprimelista(i, this.state.apipagos[dat]));
        }
        regresa.push(
          <div className=" ajustatext agranpoco" key={"a" + 2}>
            {segunaray}
          </div>
        );
        return regresa;
      }
    }
  };
  actopantaydesctual = (vale, cuesta) => {
    this.setState({
      acttipago: false,
      actiyapag: vale,
      establedialogo: cuesta,
    });
  };

  elbotonpago = () => {
    var ub, med, link;
    if (this.state.establedialogo === 1) {
      return (
        <div className="elcontplan realtivo soylaimagen">
          <div
            className="elboton estadentro"
            onClick={() => {
              window.open("https://www.nequi.com.co/mapas/");
            }}
          >
            {" "}
            efectivo &nbsp; <i className="far fa-coins"></i>
          </div>
          <div
            className="elboton estadentro"
            onClick={() => {
              window.open("https://recarga.nequi.com.co/bdigitalpsl/#!/");
            }}
          >
            tarjeta &nbsp;<i className="far fa-credit-card"></i>
          </div>
        </div>
      );
    } else if (this.state.establedialogo === 2) {
      if (this.state.pagpro !== -1) {
        ub = 0;
        med = this.state.pagpro;
      } else if (this.state.pagasis !== -1) {
        ub = 1;
        med = this.state.pagasis;
      } else {
        ub = 1;
        med = 0;
      }
      if (this.state.apipagos[ub] !== undefined) {
        if (this.state.apipagos[ub][med] !== undefined) {
          if (this.state.apipagos[ub][med].linkpayu !== undefined) {
            link = this.state.apipagos[ub][med].linkpayu;
          }
        }
      }
      return (
        <div className="elcontplan realtivo soylaimagen">
          <div
            className="elboton estadentro"
            onClick={() => {
              window.open(link);
            }}
          >
            {" "}
            {this.props.eltexto(76)} &nbsp; <i className="far fa-coins"></i>
          </div>
        </div>
      );
    } else if (this.state.establedialogo === 3) {
      if (this.state.pagpro !== -1) {
        ub = 0;
        med = this.state.pagpro;
      } else if (this.state.pagasis !== -1) {
        ub = 1;
        med = this.state.pagasis;
      } else {
        ub = 1;
        med = 0;
      }
      if (this.state.apipagos[ub] !== undefined) {
        if (this.state.apipagos[ub][med] !== undefined) {
          if (this.state.apipagos[ub][med].linkmercpago !== undefined) {
            link = this.state.apipagos[ub][med].linkmercpago;
          }
        }
      }
      return (
        <div className="elcontplan realtivo soylaimagen">
          <div
            className="elboton estadentro"
            onClick={() => {
              window.open(link);
            }}
          >
            {" "}
            {this.props.eltexto(76)} &nbsp; <i className="far fa-coins"></i>
          </div>
        </div>
      );
    }
  };
  format = (datos) => {
    var num = datos;
    if (!isNaN(num)) {
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      num = num.split("").reverse().join("").replace(/^\./, "");
      return num;
    }
  };

  devuelvenum = () => {
    var ub, med;
    if (this.state.pagpro !== -1) {
      ub = 0;
      med = this.state.pagpro;
    } else if (this.state.pagasis !== -1) {
      ub = 1;
      med = this.state.pagasis;
    } else {
      ub = 1;
      med = 0;
    }
    if (this.state.apipagos[ub] !== undefined) {
      if (this.state.apipagos[ub][med] !== undefined) {
        if (this.state.apipagos[ub][med].valor !== undefined) {
          return this.format(this.state.apipagos[ub][med].valor);
        }
      }
    }
  };
  render = () => {
    if (this.state.panta === 1) {
      return (
        <div className="marabajo">
          <div className="textotimipe">{this.props.eltexto(1)}</div>
          <div className="contenidoperfil">
            <div className="textosdatosuser">
              <div className="punticos">
                {this.props.eltexto(16)}: {this.state.yosoy.nom}{" "}
              </div>
              <div className="punticos">
                {this.props.eltexto(42)}: {this.state.yosoy.user}
              </div>
              <div className="punticos">
                {this.props.eltexto(17)}: {this.state.yosoy.correo}
              </div>
              <div className="punticos">
                {this.props.eltexto(41)}: {this.state.yosoy.tel}
              </div>
            </div>
            {this.veriboton(1)}
          </div>
          {this.verificacota()}
          {this.verificacont()}
        </div>
      );
    } else if (this.state.panta === 2) {
      return (
        <div className="marabajo">
          <div className="editusericon">
            <i
              className="far fa-arrow-left elho"
              onClick={() => this.editusuario(1)}
            ></i>
          </div>
          <div>{this.props.eltexto(52)}</div>
          <Editusuario
            eltexto={this.props.eltexto}
            yosoy={this.state.yosoy}
            loguea={this.props.loguea}
          />
        </div>
      );
    } else if (this.state.panta === 6) {
      return (
        <div className="marabajo">
          {this.props.eltexto(145)}
          <div className="ajustatext">{this.vericontra()}</div>
          {this.veriboton(6)}
        </div>
      );
    } else if (this.state.panta === 7) {
      return (
        <div className="marabajo">
          <div className="textotimipe conboton alaiz nomecambie">
            <div className="iconosconfirm elderriba">
              <div className="iconopuntual">
                <i className="far fa-window-close"></i>
              </div>
            </div>
            {this.props.eltexto(146)}
            <div className="iconosconfirm eldebaj">
              <div className="iconopuntual" onClick={() => this.editusuario(1)}>
                <i className="far fa-window-close"></i>
              </div>
            </div>
          </div>

          {this.cargaproyect(0)}
          {this.cargaproyect(1)}

          <div className="iconosconfirm">
            <div className="iconopuntual" onClick={() => this.editusuario(1)}>
              <i className="far fa-window-close"></i>
              <div className="textoicono">{this.props.eltexto(54)}</div>
            </div>
          </div>
          <div
            className="flotanteexplic quitahe alervisu colm"
            style={this.ene()}
          >
            <div
              className="yosoyelclick"
              onClick={() => this.abremepago(false)}
            ></div>
            <div className="xinservible" onClick={() => this.abremepago(false)}>
              <i className="far fa-times"></i>
            </div>
            {this.props.eltexto(157)}
            <div className="elcontplan realtivo soylaimagen">
              <div
                className="elboton estadentro"
                onClick={() => {
                  this.actopantaydesctual(true, 1);
                }}
              >
                nequi
              </div>
              <div
                className="elboton estadentro"
                onClick={() => {
                  this.actopantaydesctual(true, 2);
                }}
              >
                Payu
              </div>
              <div
                className="elboton estadentro"
                onClick={() => {
                  this.actopantaydesctual(true, 3);
                }}
              >
                mercadopago
              </div>
            </div>
          </div>

          <div
            className="flotanteexplic quitahe alervisu colm"
            style={this.eye()}
          >
            <div
              className="yosoyelclick"
              onClick={() => this.actopantaydesctual(false, 0)}
            ></div>
            <div
              className="xinservible"
              onClick={() => this.actopantaydesctual(false, 0)}
            >
              <i className="far fa-times"></i>
            </div>
            <div className="contflota">
              <div className="textnormal lapaiz">
                <div className="gnoir centaygran">
                  {this.props.eltexto(158)} ${this.devuelvenum()} COP
                </div>
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.eltexto(158 + this.state.establedialogo),
                  }}
                ></div>
                {this.elbotonpago()}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.panta === 8) {
      return (
        <div className="marabajo">
          <div className="textotimipe conboton alaiz nomecambie">
            <div className="iconosconfirm elderriba">
              <div className="iconopuntual">
                <i className="far fa-window-close"></i>
              </div>
            </div>
            {this.props.eltexto(147)}
            <div className="iconosconfirm eldebaj">
              <div className="iconopuntual" onClick={() => this.editusuario(1)}>
                <i className="far fa-window-close"></i>
              </div>
            </div>
          </div>
          {this.traeproces()}
          <div className="iconosconfirm">
            <div className="iconopuntual" onClick={() => this.editusuario(1)}>
              <i className="far fa-window-close"></i>
              <div className="textoicono">{this.props.eltexto(54)}</div>
            </div>
          </div>

          <div
            className="flotanteexpli quitahe alervisu verla"
            style={this.ele()}
            dangerouslySetInnerHTML={{ __html: this.props.eltexto(152) }}
            onClick={() => this.muestralapanta(false)}
          ></div>

          <div className="flotanteexplic quitahe alervisu" style={this.eme()}>
            <div
              className="tapatodo verla"
              onClick={() => this.activetamesa(false)}
            ></div>
            <div className="conchat chat">
              {this.imprimechat()}

              <div className="ventachat" style={this.ese()}>
                <TextareaAutosize
                  type="text"
                  className="ilinput latextarea quitarmar"
                  minRows={1}
                  maxRows={2}
                  maxLength={600}
                  name="enviatexto"
                  onChange={this.handleChanges}
                  value={this.state.enviatexto}
                  placeholder={this.props.eltexto(153)}
                  onClick={() =>
                    setTimeout(() => {
                      var objDiv = document.getElementsByClassName("conchat");
                      objDiv[0].scrollTop = objDiv[0].scrollHeight;
                    }, 500)
                  }
                />
                <i
                  className={this.eve()}
                  onClick={() => {
                    if (!this.state.carga) this.envmensaje();
                  }}
                ></i>
              </div>
            </div>

            <div
              className="xinservible"
              onClick={() => this.activetamesa(false)}
            >
              <i className="far fa-times"></i>
            </div>
          </div>
        </div>
      );
    } else if (this.state.coti[this.state.actico].enviad === 1) {
      return (
        <div className="marabajo">
          <div className="editusericon">
            <i
              className="far fa-arrow-left elho"
              onClick={() => this.editusuario(1)}
            ></i>
          </div>
          {this.props.eltexto(56)}
        </div>
      );
    } else {
      if (this.state.panta === 3) {
        return (
          <div className="marabajo">
            {this.props.eltexto(53)}
            {this.veriboton(9)}
          </div>
        );
      } else if (this.state.panta === 4) {
        return (
          <div className="marabajo">
            {this.props.eltexto(49)}
            <div className="ajustatext">{this.props.eltexto(55)}</div>
            <form onSubmit={this.cuandosuba} className="preguncuest">
              <TextareaAutosize
                type="text"
                className="ilinput latextarea"
                minRows={2}
                maxLength={600}
                name="extamensa"
                onChange={this.handleChange}
                value={this.state.extamensa}
                placeholder={this.props.eltexto(57)}
              />
            </form>
            {this.veriboton(8)}
          </div>
        );
      } else if (this.state.panta === 5) {
        return (
          <div className="marabajo">
            {this.props.eltexto(47)}
            {this.veriboton(7)}

            <Editcoti
              eltexto={this.props.eltexto}
              lacotac={this.state.coti[this.state.actico].respuestas}
              loguea={this.props.loguea}
              langua={this.props.langua}
              editado={this.traestates}
            />
          </div>
        );
      }
    }
  };
}
export default Elperfil;
