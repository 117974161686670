import React, { Component } from "react";
import axios from "axios";

class Editusuario extends Component {
  state = {
    veriacti: false,
    resp: "",
    nom: this.props.yosoy.nom,
    correo: this.props.yosoy.correo,
    tel: this.props.yosoy.tel,
  };
  cuandosuba = (e) => {
    e.preventDefault();
    var obj = {
      nom: this.state.nom,
      correo: this.state.correo,
      tel: this.state.tel,
    };
    obj = Object.assign(obj, this.props.loguea);
    axios
      .post("https://api.desenfrenadonetworks.com/perfilus/actusario", obj)
      .then((response) => {
        if (response.data === "guardado") {
          window.location.replace("");
          localStorage.setItem("name", this.state.nom);
        } else {
          this.setState({ veriacti: true, resp: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  stiloint = () => {
    return {
      display: this.state.veriacti ? "flex" : "none",
    };
  };
  render = () => {
    return (
      <form onSubmit={this.cuandosuba} className="preguncuest">
        <div className="error" style={this.stiloint()}>
          {this.state.resp}
        </div>
        <div className="textocuestio">{this.props.eltexto(16)}:</div>
        <input
          type="text"
          className="ilinput"
          name="nom"
          onChange={this.handleChange}
          value={this.state.nom}
          placeholder={this.props.eltexto(16)}
          required
        />

        <div className="textocuestio">{this.props.eltexto(17)}:</div>
        <input
          type="text"
          className="ilinput"
          name="correo"
          onChange={this.handleChange}
          value={this.state.correo}
          placeholder={this.props.eltexto(17)}
          required
        />

        <div className="textocuestio">{this.props.eltexto(41)}:</div>
        <input
          type="number"
          className="ilinput"
          name="tel"
          onChange={this.handleChange}
          value={this.state.tel}
          placeholder={this.props.eltexto(41)}
          required
          min="7"
        />
        <button type="submit" className="elboton">
          {this.props.eltexto(51)}
        </button>
      </form>
    );
  };
}
export default Editusuario;
