import React, { Component } from "react";

class Nosotro extends Component {
  state = {
    elactivo: 0,
  };

  abretex = (id) => {
    var obj = this.props.content[id - 1];
    if (obj === undefined) {
      return "cargando...";
    } else {
      var valores = Object.values(obj);
      return valores[1];
    }
  };
  stilointeractiv = () => {
    return {
      opacity: this.props.vacamb ? 0 : 1,
    };
  };
  stilaint = () => {
    var elmulti = this.state.elactivo * -100;
    return {
      marginLeft: elmulti + "%",
    };
  };
  stilainta = (leentra) => {
    if (leentra === this.state.elactivo) {
      return {
        transform: "scale(1)",
        filter: "blur(0)",
      };
    } else {
      return {
        transform: "scale(4)",
        filter: "blur(10px)",
      };
    }
  };
  elintera = (value) => {
    if (value === this.state.elactivo) {
      return {
        opacity: "0",
      };
    }
  };
  stiopa = (value) => {
    if (value === this.state.elactivo) {
      return {
        opacity: "1",
      };
    } else {
      return {
        opacity: "0",
      };
    }
  };
  cambpa = (elsent) => {
    var elda;
    if (elsent === -1 && this.state.elactivo <= 0) {
    } else if (elsent === 1 && this.state.elactivo >= 6) {
    } else if (elsent === true) {
      if (this.state.elactivo < 6) {
        elda = this.state.elactivo + 1;
        this.setState({ elactivo: elda });
      } else {
        elda = 0;
        this.setState({ elactivo: elda });
      }
    } else {
      elda = this.state.elactivo + elsent;
      this.setState({ elactivo: elda });
    }
  };

  traelosslide = () => {
    let huracan = [],
      buscalef = [];
    for (var i = 0; i < 7; i++) {
      buscalef = [];
      var elrpi = (
        <div className="solamitad" key={i + 100}>
          <img
            src={"iconos/pre" + (i + 1) + ".svg"}
            alt={this.abretex(i + 101)}
            className="imagencien"
            style={this.stilainta(i)}
          />
        </div>
      );
      var elseg = (
        <div
          className="solamitad eltexdequetra"
          key={i + 1000}
          style={this.stiopa(i)}
        >
          <div className="solounnumero">{i + 1}</div>
          <div className="solountexto">{this.abretex(i + 101)}</div>
        </div>
      );
      if (i % 2 === 0) {
        buscalef.push(elseg);
        buscalef.push(elrpi);
      } else {
        buscalef.push(elrpi);
        buscalef.push(elseg);
      }
      huracan.push(
        <div className="elrecuamovetrata" key={i + 1}>
          {buscalef}
        </div>
      );
    }
    return huracan;
  };

  render = () => {
    return (
      <div className="cuerpo" style={this.stilointeractiv()}>
        <div className="titulopre">
          <div className="eldelosbotones">{this.abretex(3)}</div>
          <div className="eldelosbotones">
            <i
              className="fas fa-chevron-circle-left colomora grandegrandesito"
              style={this.elintera(0)}
              onClick={() => {
                this.cambpa(-1);
              }}
            ></i>
            <i
              className="fas fa-chevron-circle-right colomora grandegrandesito"
              style={this.elintera(6)}
              onClick={() => {
                this.cambpa(1);
              }}
            ></i>
          </div>
          <div className="ventadevizual">
            <div className="contenelazonas" style={this.stilaint()}>
              {this.traelosslide()}
            </div>
          </div>
        </div>
      </div>
    );
  };
}
export default Nosotro;
