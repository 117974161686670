import React, { Component } from "react";

import Respuesta from "./respuesta";
import Result from "./result";
import Compedita from "./compedita";

class Pregurep extends Component {
  state = {
    contr: true,
    quieresabe: false,
    laexpli: "",
    editacam: false,
    noleadado: false,
  };
  callAPI = (link) => {
    fetch(link)
      .then((response) => {
        return response.json();
      })
      .then((content) => this.setState({ apiResponse: content }))
      .catch((err) => err);
  };

  abretex = () => {
    var obj = this.props.laotra;
    if (obj === undefined) {
      return "cargando...";
    } else {
      var segmed = obj[0];
      if (segmed === undefined) {
        if (this.state.contr) {
          this.props.cambio();
          this.actualiza();
          this.setState({ contr: false });
        } else {
          return (
            <Result eltexto={this.props.eltext} total={this.props.total} />
          );
        }
      } else {
        if (this.state.contr === false) {
          this.setState({ contr: true });
        }

        var valores = Object.values(segmed);

        return valores[0];
      }
    }
  };
  newac = (vat) => {
    this.props.cambio();
    this.props.resp(vat, true);
  };

  actualiza = (vat) => {
    if (!this.props.vacamb) {
      window.setTimeout(this.newac(vat), 1000);
    }
  };
  stilointerac = () => {
    return {
      opacity: this.props.vacamb ? "0" : null,
    };
  };
  elestiinte = () => {
    return {
      top: this.state.quieresabe ? "0" : "130%",
      opacity: this.state.quieresabe ? null : "0",
    };
  };
  elestiinteractuando = () => {
    return {
      top: this.state.editacam ? "0" : "130%",
      opacity: this.state.editacam ? null : "0",
    };
  };
  enviarob = () => {
    var obj = this.props.laotra;

    if (obj === undefined) {
      return "";
    } else {
      let imagencita = [];
      for (var i = 0; i < obj[1].length; i++) {
        let segmed = obj[1][i];
        var valores = Object.values(segmed);
        imagencita.push(
          <Respuesta
            activa={this.actualiza}
            resp={this.props.resp}
            key={i}
            valor={valores[0]}
            urls={valores[1]}
            ident={valores[2]}
          />
        );
      }

      return imagencita;
    }
  };
  activanose = () => {
    if (this.state.noleadado === false) {
      this.setState({ noleadado: true });
    }
    document.body.style.overflow = "hidden";
    document.body.style.overflowX = "hidden";
    this.setState({ quieresabe: true });
    var obj = this.props.laotra[0];
    if (obj === undefined) {
      this.setState({ laexpli: "error" });
    } else {
      var segmed = obj["exp_" + this.props.langua];
      this.setState({ laexpli: segmed });
    }
  };
  desactivanose = () => {
    document.body.style.overflow = "visible";
    document.body.style.overflowX = "hidden";
    this.setState({ quieresabe: false });
  };
  muestrajustes = () => {
    this.setState({ editacam: true });
    this.setState({ quieresabe: false });
  };
  quitajustes = () => {
    this.setState({ editacam: false });
    this.setState({ quieresabe: true });
  };
  devuelveclase = () => {
    if (this.state.noleadado === false) {
      if (this.props.lapre === 1 || this.props.lapre === 2) {
        return "eliconopregunta grandegrande";
      } else {
        return "eliconopregunta";
      }
    } else {
      return "eliconopregunta";
    }
  };

  elporcenta = () => {
    return {
      width: this.props.percent() + "%",
    };
  };
  colordela = () => {
    if (this.props.percent() < 50)
      return {
        color: "#f2f2f2",
      };
  };
  traelabarra = () => {
    if (
      this.props.lapre !== 1 &&
      this.props.lapre !== 2 &&
      this.props.lapre !== 68 &&
      this.props.lapre !== 48
    )
      return (
        <div className="contebarcarga">
          <div className="absoluta" style={this.colordela()}>
            {this.props.percent()} %
          </div>
          <div className="barload" key="barra">
            <div className="llenandobarra" style={this.elporcenta()}></div>
          </div>
        </div>
      );
  };

  render = () => {
    return (
      <div className="cuerpo" style={this.stilointerac()}>
        {this.traelabarra()}
        <div className="titulopre">{this.abretex()}</div>
        {this.enviarob()}
        <div className={this.devuelveclase()} onClick={this.activanose}>
          <img src="iconos/bombi.svg" alt="duda" />
          <div className="emerterxt">{this.props.eltext(165)}</div>
        </div>
        <div className="flotanteexpli" style={this.elestiinte()}>
          <div className="yosoyelclick" onClick={this.desactivanose}></div>
          <div className="xinservible" onClick={this.desactivanose}>
            <i className="fas fa-times"></i>
          </div>
          <div className="contflota" onClick={this.desactivanose}>
            <div
              className="textnormal"
              dangerouslySetInnerHTML={{ __html: this.state.laexpli }}
            ></div>
          </div>
          <div className="eliconopreguntan" onClick={this.muestrajustes}>
            <img src="iconos/confi.svg" alt="duda" />
          </div>
        </div>
        <div
          className="flotanteexplic quitahe"
          style={this.elestiinteractuando()}
        >
          <div className="yosoyelclick" onClick={this.quitajustes}></div>
          <div className="xinservible" onClick={this.quitajustes}>
            <i className="fas fa-times"></i>
          </div>

          <Compedita
            eltexto={this.props.eltext}
            cambid={this.props.cambid}
            activate={this.props.activate}
            verifactivo={this.props.verifactivo}
            loidio={this.props.loidio}
            langua={this.props.langua}
            cambmoned={this.props.cambmoned}
            moned={this.props.moned}
            anch={this.props.anch}
            reini={false}
          />
        </div>
      </div>
    );
  };
}

export default Pregurep;
