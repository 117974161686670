import React, { Component } from "react";
import axios from "axios";

import Cuerpo from "./components/cuerpo";
import Imagenes from "./components/imagenes";

import "./estilos/estilo.css";

class App extends Component {
  state = {
    confirm: true,
    x: 0,
    altu: 0,
    acho: 0,
    escena: 4,
    cambio: false,
    setdeanim: 1,
    pregu: 1,
    identifi: [],
    resp: [],
    activate: true,
    paso: false,
    prende: true,
    apiResponse: [],
    bajalat: true,
  };
  quitacarga = () => {
    this.setState({ prende: false });

    setTimeout(() => {
      this.setState({ paso: true });
      this.setState({ prende: true });
    }, 1000);
  };
  funcionactualiza = (user, has, nom) => {
    this.setState({
      identifi: { user: user, caca: has, tunon: nom },
    });
  };

  desact = () => {
    var medi = true;
    if (this.state.activate) medi = false;
    localStorage.setItem("paractivo", medi);
    this.setState({ activate: medi });
  };

  isMobile = (val = false) => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return false;
    } else if (this.state.activate === true && val === false) {
      return true;
    } else {
      return false;
    }
  };
  compruebauser = (e) => {
    var cache = {
      user: localStorage.getItem("userio"),
      caca: localStorage.getItem("idea"),
      tunon: localStorage.getItem("name"),
    };
    var respuestas = localStorage.getItem("respues");
    if (respuestas) {
      var obj = JSON.parse("[" + respuestas + "]");
      this.setState({
        resp: obj,
      });
    }
    var pregunta = parseInt(localStorage.getItem("pregunt"));
    if (pregunta) {
      this.setState({
        pregu: pregunta,
      });
    }
    if (cache.user !== null) {
      axios
        .post("https://api.desenfrenadonetworks.com/login/cachas", cache)
        .then((response) => {
          if (response.data[0].pase === "soyinvitado") {
            this.setState({
              identifi: {
                user: "soyinvitado",
                caca: "soyinvitado",
                tunon: "soyinvitado",
              },
            });
          } else {
            this.setState({
              identifi: cache,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.setState({
        identifi: {
          user: "soyinvitado",
          caca: "soyinvitado",
          tunon: "soyinvitado",
        },
      });
    }

    var paractiv = localStorage.getItem("paractivo");

    if (paractiv !== null) {
      if (paractiv === "true") paractiv = true;
      else {
        paractiv = false;
      }
      this.setState({
        activate: paractiv,
      });
    }
  };

  timerdescuento = () => {
    this.setState({ cambio: true });
    window.setTimeout(this.actua(-1), 1000);
    window.setTimeout(this.cambiarfondo, 1000);
  };
  callAPI = () => {
    fetch("https://api.desenfrenadonetworks.com/imagenes.json")
      .then((response) => {
        return response.json();
      })
      .then((content) => this.setState({ apiResponse: content }))
      .catch((err) => err);
  };

  componentDidMount() {
    this.compruebauser();
    this.setState({
      altu: window.innerHeight,
      anch: window.innerWidth,
    });
    this.callAPI();
  }

  semovio = (event) => {
    if (this.isMobile() && this.state.bajalat) {
      this.setState({
        x:
          -((Math.round((100 * event.clientX) / window.innerWidth) - 50) * 2) /
          100,
        bajalat: false,
      });

      setTimeout(() => {
        this.setState({
          altu: window.innerHeight,
          anch: window.innerWidth,
          bajalat: true,
        });
      }, 100);
    } else {
      if (this.state.bajalat) {
        this.setState({
          bajalat: false,
        });
        setTimeout(() => {
          this.setState({
            altu: window.innerHeight,
            anch: window.innerWidth,
            bajalat: true,
          });
        }, 100);
      }
    }
  };
  cambiarfondo = () => {
    var cambfondo = 1;
    var contenido = 1;
    if (5 > this.state.escena) {
      contenido = this.state.escena + 1;
    }
    if (this.state.setdeanim < 3) {
      cambfondo = this.state.setdeanim + 1;
    }
    this.setState({ setdeanim: cambfondo });
    this.setState({ escena: contenido });
    this.setState({ cambio: false });
  };
  newac = (val) => {
    var valor = this.state.pregu + val;
    this.setState({ pregu: valor });
  };

  actua = (val = 1) => {
    this.setState({ cambio: true });
    this.setState({ contrul: true });
    window.setTimeout(this.cambiarfondo, 1000);
  };
  actualiza = () => {
    this.setState({ cambio: true });
    window.setTimeout(this.cambiarfondo, 1000);
  };

  anaderesp = (val, vomp) => {
    if (val) {
      var cont = this.state.resp;
      var larg = cont.length;
      if (vomp === true) {
        cont[larg] = val;
      } else {
        cont.splice(larg - 1, 1);
      }
      this.setState({ resp: cont });
    }
  };
  actilo = () => {
    return {
      opacity: this.state.prende ? 1 : 0,
    };
  };

  stiloint = () => {
    return {
      opacity: this.state.cambio ? "0" : "1",
    };
  };
  verifica = () => {
    if (this.state.paso && window.location.pathname !== "/tservice") {
      if (this.state.anch > 800) {
        return (
          <div className="adarorden">
            <Imagenes
              lax={this.state.x}
              alto={this.state.altu}
              ancho={this.state.anch}
              escen={this.state.escena}
              cambio={this.state.cambio}
              animales={this.state.setdeanim}
              cargo={this.state.cargo}
              paso={this.state.paso}
              apiResponse={this.state.apiResponse}
            />
          </div>
        );
      } else {
        var fondo = "/iconos/esc" + this.state.escena + ".webp";
        return (
          <div className="fondomobile" style={this.stiloint()}>
            <img
              src={fondo}
              alt={"fondo " + this.state.escena}
              className="imagenla"
            />
          </div>
        );
      }
    } else {
      if (this.state.anch < 850) {
        if (this.state.paso) {
          fondo = "./iconos/esc" + this.state.escena + ".webp";
          return (
            <div className="fondomobile" style={this.stiloint()}>
              <img
                src={fondo}
                alt={"fondo " + this.state.escena}
                className="imagenla"
              />
            </div>
          );
        }
      }
    }
  };

  render = () => {
    return (
      <div onMouseMove={this.semovio} className="padre" style={this.actilo()}>
        <Cuerpo
          cambio={this.actualiza}
          mirame={this.actua}
          logueado={this.state.identifi}
          vacamb={this.state.cambio}
          pregunta={this.state.pregu}
          timerdes={this.timerdescuento}
          resp={this.anaderesp}
          actilaz={this.funcionactualiza}
          total={this.state.resp}
          newac={this.newac}
          activate={this.desact}
          verifactivo={this.state.activate}
          anch={this.state.anch}
          quitacarga={this.quitacarga}
          paso={this.state.paso}
        />
        {this.verifica()}
      </div>
    );
  };
}
export default App;
