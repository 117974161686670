import React, { Component } from "react";

import "../estilos/muneco.css";

class Tisevic extends Component {
  state = {
    elactivo: 0,
    elactivap: 0,
    elactilus: 0,
    elactivid: 0,
    elactivir: 0,
    videojurl: [
      "https://www.youtube.com/embed/rvU1oyR8ng8",
      "https://www.youtube.com/embed/MM2ohF7fyCM",
    ],
    vrjurl: [
      "https://www.youtube.com/embed/srG8PYC9xkY",
      "https://www.youtube.com/embed/-gFU4JmluAw",
    ],
  };

  movimiento = () => {
    let tarcie = 1 / 6;
    let cialo_x = 0,
      cialoRuchX = 0,
      uszy_x = 0,
      uszy_y = 0,
      uszyRuchX = 0;
    let uszyRuchY = 0,
      zrenica_x = 0,
      zrenica_y = 0,
      zrenicaRuchX = 0;
    let zrenicaRuchY = 0,
      oko_x = 0,
      oko_y = 0,
      okoRuchX = 0,
      okoRuchY = 0;
    let powieka_gorna_x = 0,
      powieka_gorna_y = 0,
      powiekaGornaRuchX = 0,
      powiekaGornaRuchY = 0;
    let powieka_dolna_x = 0,
      powieka_dolna_y = 0,
      powiekaDolnaRuchX = 0,
      powiekaDolnaRuchY = 0;
    let usta_x = 0,
      usta_y = 0,
      ustaRuchX = 0,
      ustaRuchY = 0;
    function animacja() {
      cialo_x += (cialoRuchX - cialo_x) * tarcie;
      var els = document.querySelectorAll(".cialo");
      els.forEach(function (el, ind) {
        el.style.transform = "rotate(" + -cialo_x + "deg)";
      });
      uszy_x += (uszyRuchX - uszy_x) * tarcie;
      uszy_y += (uszyRuchY - uszy_y) * tarcie;
      if (uszy_x < 0) {
        els = document.querySelectorAll(".ucho.lewe");
        els.forEach(function (el, ind) {
          el.style.transform =
            "translate(" + -uszy_x * 7 + "px," + -uszy_y + "px)";
        });
        els = document.querySelectorAll(".ucho.prawe");
        els.forEach(function (el, ind) {
          el.style.transform = "translate(" + -uszy_x + "px," + -uszy_y + "px)";
        });
      } else {
        els = document.querySelectorAll(".ucho.lewe");
        els.forEach(function (el, ind) {
          el.style.transform = "translate(" + -uszy_x + "px," + -uszy_y + "px)";
        });
        els = document.querySelectorAll(".ucho.prawe");
        els.forEach(function (el, ind) {
          el.style.transform =
            "translate(" + -uszy_x * 7 + "px," + -uszy_y + "px)";
        });
      }
      zrenica_x += (zrenicaRuchX - zrenica_x) * (tarcie * 3);
      zrenica_y += (zrenicaRuchY - zrenica_y) * (tarcie * 3);
      els = document.querySelectorAll(".zrenica");
      els.forEach(function (el, ind) {
        el.style.transform =
          "translate(" + -zrenica_x + "px," + -zrenica_y + "px)";
      });
      oko_x += (okoRuchX - oko_x) * tarcie;
      oko_y += (okoRuchY - oko_y) * tarcie;
      if (oko_x < 0) {
        els = document.querySelectorAll(".oko.lewe");
        els.forEach(function (el, ind) {
          el.style.transform =
            "translate(" + -oko_x * 2 + "px," + -oko_y + "px)";
          el.style.zIndex = "2";
        });
        els = document.querySelectorAll(".oko.prawe");
        els.forEach(function (el, ind) {
          el.style.transform = "translate(" + -oko_x + "px," + -oko_y + "px)";
          el.style.zIndex = "1";
        });
      } else {
        els = document.querySelectorAll(".oko.prawe");
        els.forEach(function (el, ind) {
          el.style.transform =
            "translate(" + -oko_x * 2 + "px," + -oko_y + "px)";
          el.style.zIndex = "2";
        });
        els = document.querySelectorAll(".oko.lewe");
        els.forEach(function (el, ind) {
          el.style.transform = "translate(" + -oko_x + "px," + -oko_y + "px)";
          el.style.zIndex = "1";
        });
      }
      powieka_gorna_x += (powiekaGornaRuchX - powieka_gorna_x) * tarcie;
      powieka_gorna_y += (powiekaGornaRuchY - powieka_gorna_y) * tarcie;
      els = document.querySelectorAll(".powieka_gorna");
      els.forEach(function (el, ind) {
        el.style.transform =
          "translate(" + -powieka_gorna_x + "px," + -powieka_gorna_y + "px)";
      });
      powieka_dolna_x += (powiekaDolnaRuchX - powieka_dolna_x) * tarcie;
      powieka_dolna_y += (powiekaDolnaRuchY - powieka_dolna_y) * tarcie;
      els = document.querySelectorAll(".powieka_dolna");
      els.forEach(function (el, ind) {
        el.style.transform =
          "translate(" + -powieka_dolna_x + "px," + -powieka_dolna_y + "px)";
      });
      usta_x += (ustaRuchX - usta_x) * tarcie;
      usta_y += (ustaRuchY - usta_y) * tarcie;
      els = document.querySelectorAll(".usta");
      els.forEach(function (el, ind) {
        el.style.transform =
          "translate(" + -usta_x + "px," + -usta_y * 2 + "px)";
      });
      window.requestAnimationFrame(animacja);
    }
    window.onmousemove = function (e) {
      var lMouseX = Math.max(
        -100,
        Math.min(100, window.innerWidth / 2 - e.clientX)
      );
      var lMouseY = Math.max(
        -100,
        Math.min(100, window.innerHeight / 2 - e.clientY)
      );
      cialoRuchX = (5 * lMouseX) / 200;
      uszyRuchX = (3 * -lMouseX) / 100;
      uszyRuchY = (10 * -lMouseY) / 100;
      zrenicaRuchX = (25 * lMouseX) / 200;
      zrenicaRuchY = (13 * lMouseY) / 100;
      okoRuchX = (20 * lMouseX) / 100;
      if (lMouseY <= 0) {
        okoRuchY = (10 * lMouseY) / 100;
        powiekaDolnaRuchY = (25 * lMouseY) / 100;
      } else {
        okoRuchY = (30 * lMouseY) / 100;
        powiekaDolnaRuchY = (55 * lMouseY) / 100;
      }
      powiekaGornaRuchX = (25 * lMouseX) / 100;
      powiekaGornaRuchY = (35 * lMouseY) / 100;
      powiekaDolnaRuchX = (25 * lMouseX) / 100;
      ustaRuchX = (35 * lMouseX) / 100;
      ustaRuchY = (10 * lMouseY) / 100;
    };
    window.onclick = function (e) {
      var lMouseX = Math.max(
        -100,
        Math.min(100, window.innerWidth / 2 - e.clientX)
      );
      var lMouseY = Math.max(
        -100,
        Math.min(100, window.innerHeight / 2 - e.clientY)
      );
      cialoRuchX = (5 * lMouseX) / 200;
      uszyRuchX = (3 * -lMouseX) / 100;
      uszyRuchY = (10 * -lMouseY) / 100;
      zrenicaRuchX = (25 * lMouseX) / 200;
      zrenicaRuchY = (13 * lMouseY) / 100;
      okoRuchX = (20 * lMouseX) / 100;
      if (lMouseY <= 0) {
        okoRuchY = (10 * lMouseY) / 100;
        powiekaDolnaRuchY = (25 * lMouseY) / 100;
      } else {
        okoRuchY = (30 * lMouseY) / 100;
        powiekaDolnaRuchY = (55 * lMouseY) / 100;
      }
      powiekaGornaRuchX = (25 * lMouseX) / 100;
      powiekaGornaRuchY = (35 * lMouseY) / 100;
      powiekaDolnaRuchX = (25 * lMouseX) / 100;
      ustaRuchX = (35 * lMouseX) / 100;
      ustaRuchY = (10 * lMouseY) / 100;
    };
    animacja();
  };
  nosomosloelegidos(valor, elsta) {
    if (valor === elsta) {
      return {
        filter: "blur(0px) opacity(100%)",
      };
    } else {
      return {
        filter: "blur(7px) opacity(10%)",
      };
    }
  }
  lotrae = (elesen) => {
    if (elesen === "") {
      return {
        display: "none",
      };
    }
  };
  imprimesliderweb = (nombre, elstdesta, elmaximo) => {
    let losnome = [
      125,
      163,
      135,
      127,
      164,
      134,
      130,
      131,
      133,
      128,
      129,
      126,
      132,
      162,
    ];
    let respues = [];
    let loslink = [
      "http://revistanefelibata.com/",
      "",
      "https://www.progistec.com/berkley/",
      "https://alejonjoli.com/",
      "https://photos.lokodesenfrenado.com/photo/",
      "https://lokodesenfrenado.com/sources/",
      "https://appsese.co:8093/LogIn.aspx?AspxAutoDetectCookieSupport=1",
      "https://lokodesenfrenado.com/eng/",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    for (let i = 0; i < elmaximo; i++) {
      respues.push(
        <div
          key={nombre + i}
          style={this.nosomosloelegidos(i, elstdesta)}
          className="latrandeho"
        >
          <img
            src={"imagen/" + nombre + (i + 1) + ".webp"}
            alt={"mockup" + (i + 1)}
            className="imagenporta"
          />
          <div className="centraymargen">{this.props.eltext(losnome[i])}</div>
          <a
            className="abirlink"
            target="_blank"
            style={this.lotrae(loslink[i])}
            href={loslink[i]}
            rel="noopener noreferrer"
          >
            {this.props.eltext(161)}
          </a>
        </div>
      );
    }
    return respues;
  };

  imprimeslider = (nombre, elstdesta, elmaximo, arranca) => {
    let respues = [];
    for (let i = 0; i < elmaximo; i++) {
      respues.push(
        <div
          key={nombre + i}
          style={this.nosomosloelegidos(i, elstdesta)}
          className="latrandeho"
        >
          <img
            src={"imagen/" + nombre + (i + 1) + ".webp"}
            alt={"mockup" + (i + 1)}
            className="imagenporta"
          />
          <div className="centraymargen">{this.props.eltext(arranca + i)}</div>
        </div>
      );
    }
    return respues;
  };
  imprimeyoutube = (nombre, elstdesta, elmaximo, arranca, loslink) => {
    let respues = [];
    for (let i = 0; i < elmaximo; i++) {
      respues.push(
        <div
          key={nombre + i}
          style={this.nosomosloelegidos(i, elstdesta)}
          className="latrandehos"
        >
          <iframe
            title={nombre + i}
            src={loslink[i] + "?rel=0&showinfo=0&controls=1"}
            seamless="seamless"
            scrolling="no"
            className="vidporta"
          />
          <div className="centraymargen">a</div>
          <div className="centraymargen">a</div>
        </div>
      );
    }
    return respues;
  };

  traelosboto = (esleta, nomdela, elmax) => {
    return (
      <div className="eldelosbotones bajemos crecebot">
        <i
          className="fas fa-chevron-circle-left colomora grandegrandesito"
          style={this.elintera(0, esleta)}
          onClick={() => {
            this.cambpa(-1, esleta, nomdela, elmax);
          }}
        ></i>
        <i
          className="fas fa-chevron-circle-right colomora grandegrandesito"
          style={this.elintera(elmax - 1, esleta)}
          onClick={() => {
            this.cambpa(1, esleta, nomdela, elmax);
          }}
        ></i>
      </div>
    );
  };

  cambpa = (elsent, elstac, elstdesta, elmax) => {
    var elda;
    if (elsent === -1 && elstac <= 0) {
    } else if (elsent === 1 && elstac >= elmax - 1) {
    } else if (elsent === true) {
      setTimeout(() => {
        this.cambpa(true);
      }, 7000);
      if (elstac < elmax) {
        elda = elstac + 1;
        this.setState({ [elstdesta]: elda });
      } else {
        elda = 0;
        this.setState({ [elstdesta]: elda });
      }
    } else {
      elda = elstac + elsent;
      this.setState({ [elstdesta]: elda });
    }
  };

  elintera = (value, elsta) => {
    if (value === elsta) {
      return {
        opacity: "0",
      };
    }
  };

  stilaint = (elesta) => {
    var elmulti = elesta * -100;
    return {
      marginTop: "0",
      marginLeft: elmulti + "%",
    };
  };
  traegusano = () => {
    if (window.innerWidth > 850)
      return (
        <div className="pozycja">
          <div className="ludek">
            <div className="cialo">
              <div className="ucho lewe"></div>
              <div className="ucho prawe"></div>
              <div className="skora">
                <div className="oko lewe">
                  <span className="zrenica"></span>
                </div>
                <div className="oko prawe">
                  <span className="zrenica"></span>
                </div>
                <div className="powieka_gorna"></div>
                <div className="powieka_dolna"></div>
                <div className="usta"></div>
              </div>
            </div>
          </div>
        </div>
      );
  };
  componentDidMount = () => {
    this.movimiento();
  };
  render = () => {
    return (
      <div className="centra">
        {this.traegusano()}
        <div className="botonestise">
          <div className="botonizq midlo">
            <h1>{this.props.eltext(117)}</h1>
            <div className="elbotona aji">
              <div>{this.props.eltext(111)}</div>
              <div
                className="bajemos"
                dangerouslySetInnerHTML={{ __html: this.props.eltext(114) }}
              ></div>
            </div>
            <div className="elbotona aji">
              <div>{this.props.eltext(112)}</div>
              <div
                className="bajemos"
                dangerouslySetInnerHTML={{ __html: this.props.eltext(115) }}
              ></div>
            </div>
            <div className="elbotona ajis">
              <div>{this.props.eltext(113)}</div>
              <div
                className="bajemos"
                dangerouslySetInnerHTML={{ __html: this.props.eltext(116) }}
              ></div>
            </div>
          </div>
          <div className="botonder midlo">
            <h1>{this.props.eltext(118)}</h1>
            <div className="elbotona ajis">
              <div>{this.props.eltext(119)}</div>
              {this.traelosboto(this.state.elactivo, "elactivo", 14)}
              <div className="contentslideri">
                <div
                  className="bajemus estaespri"
                  style={this.stilaint(this.state.elactivo)}
                >
                  {this.imprimesliderweb("web", this.state.elactivo, 14)}
                </div>
              </div>
            </div>
            <div className="elbotona ajis">
              <div>{this.props.eltext(120)}</div>
              {this.traelosboto(this.state.elactivap, "elactivap", 7)}
              <div className="contentslideri">
                <div
                  className="bajemus estaesseg"
                  style={this.stilaint(this.state.elactivap)}
                >
                  {this.imprimeslider("app", this.state.elactivap, 7, 136)}
                </div>
              </div>
            </div>
            <div className="elbotona ajis">
              <div>{this.props.eltext(121)}</div>
              {this.traelosboto(this.state.elactilus, "elactilus", 10)}
              <div className="contentslideri">
                <div
                  className="bajemus estaester"
                  style={this.stilaint(this.state.elactilus)}
                >
                  {this.imprimeslider("ilus", this.state.elactilus, 10, 136)}
                </div>
              </div>
            </div>
            <div className="elbotona aji">
              <div>{this.props.eltext(122)}</div>
              {this.traelosboto(this.state.elactivid, "elactivid", 2)}
              <div className="contentslideri">
                <div
                  className="bajemus estaescuar"
                  style={this.stilaint(this.state.elactivid)}
                >
                  {this.imprimeyoutube(
                    "videoju",
                    this.state.elactivid,
                    2,
                    136,
                    this.state.videojurl
                  )}
                </div>
              </div>
            </div>
            <div className="elbotona aji">
              <div>{this.props.eltext(123)}</div>
              <div className="bajemus"></div>
            </div>
            <div className="elbotona aji damespacioabajo">
              <div>{this.props.eltext(124)}</div>
              {this.traelosboto(this.state.elactivir, "elactivir", 2)}
              <div className="contentslideri">
                <div
                  className="bajemus estaescuar"
                  style={this.stilaint(this.state.elactivir)}
                >
                  {this.imprimeyoutube(
                    "videoju",
                    this.state.elactivir,
                    2,
                    136,
                    this.state.vrjurl
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
export default Tisevic;
