import React, { Component } from "react";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";

class Editcoti extends Component {
  state = {
    mensaje: "",
    apiresponse: [],
    values: this.props.lacotac.split(","),
  };
  handleChanges(val, event) {
    var recibe = this.state.values;
    recibe[val] = event.target.value;

    this.setState({ values: recibe });
    this.props.editado(this.state.mensaje, recibe);
  }

  traeselects = () => {
    let obj = this.state.apiresponse;

    let resp = [],
      val = [],
      pregun = [],
      tot = [];
    for (var i = 1; i < obj.length; i++) {
      val = [];
      for (var j = 0; j < obj[i].respuestas.length; j++) {
        val[j] = (
          <option value={obj[i].respuestas[j].ID} key={obj[i].respuestas[j].ID}>
            {obj[i].respuestas[j][this.props.langua]}
          </option>
        );
      }
      let ver = i;
      resp = (
        <select
          className="ilinput dejaser"
          value={this.state.values[i]}
          onChange={(e) => this.handleChanges(ver, e)}
        >
          {val}
        </select>
      );
      pregun = <div className="textocuestio centralo">{obj[i].preguntas}:</div>;
      tot.push(
        <div key={i} className="soypreguntas">
          {pregun}
          {resp}
        </div>
      );
    }
    return tot;
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.editado(e.target.value, this.state.values);
  };
  traedenuevo = () => {
    var log = {
      iden: this.props.loguea,
      coti: this.props.lacotac,
      lang: this.props.langua,
    };
    axios
      .post("https://api.desenfrenadonetworks.com/perfilus/editpre", log)
      .then((response) => {
        this.setState({ apiresponse: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  componentDidMount = () => {
    this.traedenuevo();
  };

  render = () => {
    return (
      <form onSubmit={this.cuandosuba} className="preguncuest agranda">
        {this.traeselects()}
        <div className="ajustatext marabajo">{this.props.eltexto(58)}</div>
        <TextareaAutosize
          type="text"
          className="ilinput latextarea"
          minRows={2}
          maxLength={600}
          name="mensaje"
          onChange={this.handleChange}
          value={this.state.nom}
          placeholder={this.props.eltexto(57)}
          required
        />
      </form>
    );
  };
}
export default Editcoti;
