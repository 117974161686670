import React, { Component } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import Pregurep from "./pregurep";
import Verisuario from "./verisuario";
import Cambcontra from "./cambcontra";
import Result from "./result";
import Asesoper from "./asesoper";
import Nosotro from "./nosotro";
import Carga from "./carga";
import Tisevic from "./tiservic";
import Nofound from "./nofound";
import Elback from "./elback";
import Alerta from "./alerta";
import Landing from "./landing";

import logo from "../logo.svg";
import logor from "../logor.svg";

class Cuerpo extends Component {
  constructor(props) {
    super(props);
    this.basu = "barras";
    this.med = "barras";
    this.abaj = "barras";
    this.menu = "textos";
  }
  state = {
    apiResponse: [],
    laotrapi: [],
    laterapi: [],
    verif: true,
    langua: "",
    medi: "1",
    sentido: true,
    variacion: false,
    botonactivo: true,
    moned: "USD",
    guarcot: false,
    alertin: false,
  };

  lengua = () => {
    const elmed = localStorage.getItem("idio");
    if (elmed === null) {
      var language = window.navigator.userLanguage || window.navigator.language;
      var temp = "esp";
      if (
        language === "es-ES" ||
        language === "es" ||
        language === "es-AR" ||
        language === "es-CO" ||
        language === "es-CH" ||
        language === "es-CR" ||
        language === "es-US" ||
        language === "es-MX" ||
        language === "es-PE" ||
        language === "es-UR" ||
        language === "es-VE"
      ) {
        temp = "esp";
      } else if (
        language === "en" ||
        language === "en-US" ||
        language === "en-CA" ||
        language === "en-SA" ||
        language === "en-AU" ||
        language === "en-IN" ||
        language === "en-NZ" ||
        language === "en-UK"
      ) {
        temp = "eng";
      } else if (
        language === "fr-CA" ||
        language === "fr" ||
        language === "fr-FR" ||
        language === "fr-SU"
      ) {
        temp = "fra";
      } else {
        temp = "eng";
      }
      localStorage.setItem("idio", temp);
    } else {
      temp = elmed;
    }
    this.setState({ langua: temp });
    return temp;
  };
  verifimoney = () => {
    var lamonda = localStorage.getItem("mona");
    var temp = "USD";
    if (lamonda === null) {
      var language = window.navigator.userLanguage || window.navigator.language;
      if (language === "es-ES") temp = "EUR";
      else if (language === "es" || language === "es-CO") temp = "COP";
      else if (language === "es-CH") temp = "CLP";
      else if (language === "es-AR") temp = "ARS";
      else if (language === "es-CR") temp = "CRC";
      else if (language === "es-US") temp = "USD";
      else if (language === "es-MX") temp = "MXN";
      else if (language === "es-PE") temp = "PEN";
      else if (language === "es-UR") temp = "UYU";
      else if (language === "es-VE") temp = "VEF";
      else if (language === "en" || language === "en-US") temp = "USD";
      else if (language === "en-CA") temp = "CAD";
      else if (language === "en-SA") temp = "USD";
      else if (language === "en-AU") temp = "AUD";
      else if (language === "en-IN") temp = "INR";
      else if (language === "en-NZ") temp = "NZD";
      else if (language === "en-UK") temp = "EUR";
      else if (language === "fr-CA") temp = "CAD";
      else if (language === "fr" || language === "fr-FR") temp = "EUR";
      else if (language === "fr-SU") temp = "CHF";
    } else {
      temp = lamonda;
    }

    this.setState({ moned: temp });
    this.conviertemone();
  };

  conviertemone = () => {
    var idio = this.lengua();
    var mirada = {
      user: "silencio",
      key: "olakeaseporaca",
      idi: idio,
    };
    axios
      .post(
        "https://api.desenfrenadonetworks.com/perfilus/envdivisas.json",
        mirada
      )
      .then((response) => {
        this.setState({ laterapi: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  cambmoned = (e) => {
    this.setState({ moned: e.target.value });
    localStorage.setItem("mona", e.target.value);
  };

  callAPI = (link) => {
    var ulr = "https://api.desenfrenadonetworks.com/content/" + link + ".json";
    fetch(ulr)
      .then((response) => {
        return response.json();
      })
      .then((content) => this.setState({ apiResponse: content }))
      .catch((err) => err);
  };

  cayoAPI = (link) => {
    var mirada = {
      aberra: "lafunacla",
      anto: "nodeberiasestaracaperro",
      idia: link,
    };

    axios
      .post("https://api.desenfrenadonetworks.com/cuestion/trae.json", mirada)
      .then((content) => {
        this.setState({ laotrapi: content.data });
        this.props.quitacarga();
        setTimeout(() => {
          this.verilalerta();
        }, 5000);
        setTimeout(() => {
          this.ferifinal();
        }, 1700);
      })
      .catch((err) => err);
  };

  actilapi = (valu) => {
    if (this.state.laotrapi !== []) {
      setTimeout(() => {
        var acumul = 0;
        var tempa = true;
        var valor = 0;
        if (valu) {
          valor = 1;
        } else {
          valor = -1;
        }
        var origen = this.props.pregunta - 1;
        origen = origen + valor;

        while (tempa) {
          var primerentr = this.state.laotrapi[origen + acumul * valor];
          var ani = 0;
          if (primerentr !== undefined) {
            let compara = primerentr[0].depend.split(",");

            for (var s = 0; s < this.props.total.length; s++) {
              for (var i = 0; i < compara.length; i++) {
                if (String(this.props.total[s]) === compara[i]) {
                  ani = ani + 1;
                }
              }
            }
            if (ani === compara.length) {
            } else {
              ani = 0;
            }
            if (ani === 0) {
              acumul = acumul + 1;
            } else {
              break;
            }
          } else {
            this.setState({ variacion: true, botonactivo: false });
            localStorage.setItem("respues", this.props.total);
            localStorage.setItem("pregunt", 102);
            break;
          }
        }
        acumul = acumul + 1;
        acumul = acumul * valor;
        this.props.newac(acumul);
      }, 1000);
    }
  };
  ferifinal = () => {
    if (this.state.laotrapi.length > 0) {
      if (this.props.pregunta >= this.state.laotrapi.length) {
        this.setState({
          variacion: true,
          botonactivo: false,
        });
        localStorage.setItem("respues", this.props.total);
        localStorage.setItem("pregunt", 102);
      }
    }
  };

  verifisigua = () => {
    var esta = localStorage.getItem("debeguarlaco");
    if (esta !== null) {
      if (esta === "true") {
        this.setState({ guarcot: true });
      } else {
        this.setState({ guarcot: false });
      }
    } else {
      localStorage.setItem("debeguarlaco", false);
    }
  };

  componentDidMount = () => {
    this.callAPI(this.lengua());
    this.cayoAPI(this.lengua());
    this.verifimoney();
    this.verifisigua();
  };
  abremenu = () => {
    if (this.state.verif) {
      this.basu = "barras cruzderecha";
      this.med = "barras cruzizquierda";
      this.abaj = "barras cruzbaja";
      this.menu = "textos abretecesamo";
      this.setState({ verif: false });
    } else {
      this.basu = "barras";
      this.med = "barras";
      this.abaj = "barras";
      this.menu = "textos";
      this.setState({ verif: true });
    }
  };
  cierramenu = () => {
    this.basu = "barras";
    this.med = "barras";
    this.abaj = "barras";
    this.menu = "textos";
    this.setState({ verif: true });
  };

  abretex = (id) => {
    var obj = this.state.apiResponse[id - 1];
    if (obj === undefined) {
      return "Cargando...";
    } else {
      var valores = Object.values(obj);
      return valores[1];
    }
  };

  stilointeracti = () => {
    if (this.props.pregunta === 1 || this.props.pregunta === 2) {
      return {
        opacity: 0,
        width: "11vw",
        maxWidth: "11vh",
        minWidth: "38px",
      };
    } else {
      return {
        width: "11vw",
        maxWidth: "11vh",
        minWidth: "38px",
      };
    }
  };
  recargga = () => {
    this.props.timerdes();
    //
  };
  clickdoble = () => {
    if (this.props.vacamb === false) {
      if (this.props.pregunta === 1 || this.props.pregunta === 2) {
      } else {
        this.cierramenu();
        this.recargga();
        this.props.resp(1, false);
        this.actilapi(false);
      }
    }
  };
  quecambio = () => {
    this.setState({
      sentido: true,
    });
  };

  cambiaridioma = (val = 1) => {
    var resp;
    if (val === 1) resp = "esp";
    else if (val === 2) resp = "eng";
    else if (val === 3) resp = "fra";
    localStorage.setItem("idio", resp);
    window.location.reload();
  };
  casigua = () => {
    if (window.innerWidth < 800) {
      return logor;
    } else {
      return logo;
    }
  };
  cambun = () => {
    this.props.mirame();
    this.quecambio();
    this.actilapi(true);
  };

  traelalerta = () => {
    if (this.state.alertin) {
      return <Alerta eltext={this.abretex} />;
    }
  };

  midelporcentaje = () => {
    var primerentr = this.state.laotrapi[this.props.pregunta - 1];
    let cont = 0,
      elques = 0;
    if (primerentr !== undefined) {
      let compara = primerentr[0].depend;
      this.state.laotrapi.forEach((e, i) => {
        if (e[0].depend === compara) {
          cont += 1;
          if (i === this.props.pregunta - 1) {
            elques = cont;
          }
        }
      });
    }
    var percent = (elques / cont) * 100;
    return parseInt(percent);
  };

  verilalerta = () => {
    const elmed = localStorage.getItem("dequemehablasviejo");
    if (elmed === null || elmed === undefined) {
      this.setState({ alertin: true });
      localStorage.setItem("dequemehablasviejo", this.abretex(172));
    } else if (this.abretex(172) === "elink") {
      localStorage.setItem("dequemehablasviejo", this.abretex(172));
    } else if (elmed !== this.abretex(172)) {
      this.setState({ alertin: true });
      localStorage.setItem("dequemehablasviejo", this.abretex(172));
    }
  };
  verisiesinicio = () => {
    var laspregu = (
      <Pregurep
        key="preguntas"
        cambio={this.cambun}
        vacamb={this.props.vacamb}
        lapre={this.props.pregunta}
        timerdes={this.recargga}
        resp={this.props.resp}
        laotra={this.state.laotrapi[this.props.pregunta - 1]}
        eltext={this.abretex}
        total={this.props.total}
        langua={this.state.langua}
        cambid={this.cambiaridioma}
        loidio={this.state.laterapi}
        activate={this.props.activate}
        verifactivo={this.props.verifactivo}
        cambmoned={this.cambmoned}
        moned={this.state.moned}
        anch={this.props.anch}
        percent={this.midelporcentaje}
      />
    );
    var landi = <div key="landing"></div>;
    if (this.props.pregunta === 1) {
      landi = (
        <Landing
          key="landing"
          vacamb={this.props.vacamb}
          eltext={this.abretex}
        />
      );
    }
    return [laspregu, landi];
  };

  render = () => {
    if (this.props.paso) {
      return (
        <Router className="cuerpo">
          <div id="soycabeza"></div>
          <header className="cabecera">
            <div className="huecomenulado" style={this.stilointeracti()}>
              <Route
                exact
                path="/"
                render={() => {
                  if (this.state.botonactivo === true) {
                    return (
                      <div className="menun" onClick={this.clickdoble}>
                        <img src="iconos/espalda.svg" alt="icono volver" />
                      </div>
                    );
                  }
                }}
              />
            </div>
            <Link to="/" className="logo" onClick={this.cierramenu}>
              <img
                src={this.casigua()}
                className="imagenlo"
                alt="logo"
                onClick={this.props.cambio}
              ></img>
            </Link>
            <main className={this.menu} onClick={this.cierramenu}>
              <Link to="/" className="elboton losbotonesagrados">
                <div className="cabetext" onClick={this.props.cambio}>
                  <i className="fal fa-home fa-lg"></i> {this.abretex(143)}
                </div>
              </Link>
              <Link to="/what" className="elboton losbotonesagrados">
                <div className="cabetext" onClick={this.props.cambio}>
                  <i className="fal fa-shoe-prints fa-rotate-270 fa-lg"></i>{" "}
                  {this.abretex(3)}
                </div>
              </Link>
              <Link to="/perso" className="elboton losbotonesagrados">
                <div className="cabetext" onClick={this.props.cambio}>
                  <i className="fal fa-bullhorn fa-lg"></i> {this.abretex(2)}
                </div>
              </Link>

              <Link to="/tservice" className="elboton losbotonesagrados">
                <div className="cabetext" onClick={this.props.cambio}>
                  <i className="fal fa-rocket fa-lg"></i> {this.abretex(4)}
                </div>
              </Link>
              {/* <Link to="/menssage" className="elboton losbotonesagrados">
                <div className="cabetext" onClick={this.props.cambio}>
                  <i className="fal fa-gift-card fa-lg"></i> {this.abretex(173)}
                </div>
              </Link> */}

              <Link to="/profile" className="elboton losbotonesagrados">
                <div className="cabetext" onClick={this.props.cambio}>
                  <i className="fal fa-user fa-lg"></i> {this.abretex(1)}
                </div>
              </Link>

              <div className="contienelosdered">
                <a
                  href="https://www.instagram.com/desenfrenadonetworks/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="agrandalafu elboton"
                >
                  <div className="textoredes">Instagram</div>
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=+57300%203817679"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="agrandalafu quitult elboton"
                >
                  <div className="textoredes">Whatsapp</div>
                  <i className="fab fa-whatsapp"></i>
                </a>
              </div>
            </main>
            <div className="huecomenulado" onClick={this.abremenu}>
              <div id="menu_on">
                <span className={this.basu}></span>
                <span className={this.med}></span>
                <span className={this.abaj}></span>
              </div>
            </div>
          </header>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                if (this.state.variacion) {
                  return (
                    <Result
                      eltexto={this.abretex}
                      total={this.props.total}
                      vacamb={this.props.vacamb}
                      loidio={this.state.laterapi}
                      activate={this.props.activate}
                      verifactivo={this.props.verifactivo}
                      cambmoned={this.cambmoned}
                      moned={this.state.moned}
                      cambid={this.cambiaridioma}
                      anch={this.props.anch}
                      langua={this.state.langua}
                      loguea={this.props.logueado}
                      activaguard={this.guardacoti}
                    />
                  );
                } else {
                  return this.verisiesinicio();
                }
              }}
            />
            <Route
              exact
              path="/profile"
              render={() => {
                return (
                  <Verisuario
                    loguea={this.props.logueado}
                    content={this.state.apiResponse}
                    vacamb={this.props.vacamb}
                    actilaz={this.props.actilaz}
                    langua={this.state.langua}
                    gulacoti={this.state.guarcot}
                    total={this.props.total}
                  />
                );
              }}
            />
            <Route
              path="/verify/:pa/:use"
              render={(routerProps) => {
                return (
                  <Cambcontra
                    pa={routerProps.match.params.pa}
                    use={routerProps.match.params.use}
                    vacamb={this.props.vacamb}
                    content={this.state.apiResponse}
                    loguea={this.props.logueado}
                  />
                );
              }}
            />
            <Route
              exact
              path="/perso"
              render={() => {
                return (
                  <Asesoper
                    vacamb={this.props.vacamb}
                    content={this.state.apiResponse}
                    total={this.props.total}
                  />
                );
              }}
            />

            <Route
              exact
              path="/what"
              render={() => {
                return (
                  <Nosotro
                    vacamb={this.props.vacamb}
                    content={this.state.apiResponse}
                    total={this.props.total}
                  />
                );
              }}
            />

            <Route
              exact
              path="/tservice"
              render={() => {
                return <Tisevic eltext={this.abretex} />;
              }}
            />

            <Route
              exact
              path="/comdo"
              render={() => {
                return (
                  <Elback
                    eltext={this.abretex}
                    idio={this.state.langua}
                    logueado={this.props.logueado}
                  />
                );
              }}
            />

            <Route
              render={() => {
                return <Nofound eltext={this.abretex} />;
              }}
            />
          </Switch>
          {this.traelalerta()}
        </Router>
      );
    } else {
      return <Carga lidioma={this.state.langua} />;
    }
  };
}

export default Cuerpo;
