import React, { Component } from "react";
import axios from "axios";

class Cambcontra extends Component {
  state = {
    veriacti: false,
    apiResponse: [],
    pasuno: "",
    pasdos: "",
    resp: "",
    oldpas: "",
    limit: true,
    page: 1,
    carga: false,
  };
  callAPI = () => {
    var link =
      "https://api.desenfrenadonetworks.com/login/verify/" +
      this.props.pa +
      "/" +
      this.props.use;
    fetch(link)
      .then((response) => {
        return response.json();
      })
      .then((content) => {
        this.setState({ apiResponse: content });
      })
      .catch((err) => err);
  };
  stilointeractiv = () => {
    return {
      opacity: this.props.vacamb ? 0 : 1,
    };
  };
  stiloint = () => {
    return {
      display: this.state.veriacti ? "flex" : "none",
    };
  };
  cambioinputco = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  abretex = (id) => {
    var obj = this.props.content[id - 1];
    if (obj === undefined) {
      return "cargando...";
    } else {
      var valores = Object.values(obj);
      return valores[1];
    }
  };
  cuandosubaco = (e) => {
    this.setState({ carga: true });
    e.preventDefault();
    if (this.state.pasuno === this.state.pasdos) {
      var obj = {
        pasuno: this.state.pasuno,
        pasdos: this.state.pasdos,
        user: this.props.use,
        caca: this.state.apiResponse[0].hash,
      };
      axios
        .post("https://api.desenfrenadonetworks.com/login/changepas", obj)
        .then((response) => {
          if (
            response.data === "Error saving user" ||
            response.data === "contraseñas no son iguales" ||
            response.data === 0
          ) {
            this.setState({ resp: this.abretex(19) });
            this.setState({ veriacti: true });
            localStorage.setItem("userio", "soyinvitado");
            localStorage.setItem("idea", "soyinvitado");
            localStorage.setItem("name", "soyinvitado");
            this.props.actilaz("soyinvitado", "soyinvitado", "soyinvitado");
          } else {
            this.setState({ page: 2 });
          }
          this.setState({ carga: false });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ resp: this.abretex(19) });
      this.setState({ veriacti: true });
    }
  };
  cuandosubanu = (e) => {
    this.setState({ carga: true });
    e.preventDefault();
    if (this.state.pasuno === this.state.pasdos) {
      var obj = {
        pasuno: this.state.pasuno,
        pasdos: this.state.pasdos,
        user: this.props.loguea.user,
        caca: this.props.loguea.caca,
        oldpas: this.state.oldpas,
      };
      axios
        .post("https://api.desenfrenadonetworks.com/login/changepas", obj)
        .then((response) => {
          if (
            response.data === "Error saving user" ||
            response.data === "contraseñas no son iguales" ||
            response.data === 0
          ) {
            this.setState({ resp: this.abretex(19) });
            this.setState({ veriacti: true });
            localStorage.setItem("userio", "soyinvitado");
            localStorage.setItem("idea", "soyinvitado");
            localStorage.setItem("name", "soyinvitado");
            this.props.actilaz("soyinvitado", "soyinvitado", "soyinvitado");
          } else {
            this.setState({ page: 3 });
          }
          this.setState({ carga: false });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ resp: this.abretex(19) });
      this.setState({ veriacti: true });
    }
  };
  componentDidUpdate = () => {
    if (this.props.loguea.length !== 0) {
      if (this.state.limit) {
        if (this.props.loguea.tunon === "soyinvitado") {
          this.callAPI();
          this.setState({ page: 5 });
        }
        this.setState({ limit: false });
      }
    }
  };
  stacargando = (def) => {
    if (def) {
      return {
        display: this.state.carga ? "none" : "block",
      };
    } else {
      return {
        display: this.state.carga ? "block" : "none",
      };
    }
  };

  render = () => {
    if (this.state.apiResponse === 0) {
      window.location.replace("../../");
    } else {
      if (this.state.page === 1) {
        return (
          <form
            onSubmit={this.cuandosubanu}
            className="pregunlogin"
            style={this.stilointeractiv()}
          >
            <div className="eltitu">{this.abretex(23)}</div>
            <div className="error" style={this.stiloint()}>
              {this.state.resp}
            </div>
            <input
              type="password"
              placeholder={this.abretex(7)}
              onChange={this.cambioinputco}
              value={this.state.oldpas}
              name="oldpas"
              required
              className="ilinput"
            />

            <input
              type="password"
              placeholder={this.abretex(24)}
              onChange={this.cambioinputco}
              value={this.state.pasuno}
              name="pasuno"
              required
              className="ilinput"
              title={this.abretex(26)}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            />

            <input
              type="password"
              placeholder={this.abretex(25)}
              onChange={this.cambioinputco}
              value={this.state.pasdos}
              name="pasdos"
              required
              className="ilinput"
            />

            <button
              type="submit"
              className="elboton"
              disabled={this.state.carga}
            >
              <i
                style={this.stacargando(false)}
                className="fas fa-sync-alt giragira"
              ></i>
              <div style={this.stacargando(true)}>{this.abretex(8)}</div>
            </button>
          </form>
        );
      } else if (this.state.page === 2) {
        return (
          <div className="pregunlogin" style={this.stilointeractiv()}>
            <div className="eltitu">{this.abretex(27)}</div>
            <div
              type="submit"
              className="elboton subale"
              onClick={() => window.location.replace("../../profile")}
            >
              {this.abretex(8)}
            </div>
          </div>
        );
      } else if (this.state.page === 3) {
        return (
          <div className="pregunlogin" style={this.stilointeractiv()}>
            <div className="eltitu">{this.abretex(28)}</div>
            <div
              type="submit"
              className="elboton subale"
              onClick={() => window.location.replace("../../")}
            >
              {this.abretex(8)}
            </div>
          </div>
        );
      } else {
        return (
          <form
            onSubmit={this.cuandosubaco}
            className="pregunlogin"
            style={this.stilointeractiv()}
          >
            <div className="eltitu">{this.abretex(23)}</div>
            <div className="error" style={this.stiloint()}>
              {this.state.resp}
            </div>
            <input
              type="password"
              placeholder={this.abretex(24)}
              onChange={this.cambioinputco}
              value={this.state.pasuno}
              name="pasuno"
              required
              className="ilinput"
              title={this.abretex(26)}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            />

            <input
              type="password"
              placeholder={this.abretex(25)}
              onChange={this.cambioinputco}
              value={this.state.pasdos}
              name="pasdos"
              required
              className="ilinput"
            />

            <button
              type="submit"
              className="elboton"
              disabled={this.state.carga}
            >
              <i
                style={this.stacargando(false)}
                className="fas fa-sync-alt giragira"
              ></i>
              <div style={this.stacargando(true)}>{this.abretex(8)}</div>
            </button>
          </form>
        );
      }
    }
  };
}

export default Cambcontra;
