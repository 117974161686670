import React, { Component } from "react";

class Contrusvj extends Component {
  state = {
    esc: this.props.esc,
  };

  compropro() {
    if (this.props.altpan / 950 > this.props.ancpan / 1440) {
      return this.props.ancpan / 1920;
    } else {
      return this.props.altpan / 960;
    }
  }
  lalturesponsive() {
    if (this.props.altpan / 950 > this.props.ancpan / 1440) {
      return this.props.altpan - (0.45 * this.props.ancpan + 90);
    } else {
      return 0;
    }
  }
  comprobaltu() {
    return (this.props.posy / 100) * 950 * this.compropro();
  }
  comprobanch() {
    return (this.props.posx / 100) * 1920 * this.compropro();
  }
  escala() {
    return this.state.esc * this.compropro();
  }
  borde() {
    if (this.props.altpan / 950 > this.props.ancpan / 1440) {
      return (this.props.ancpan - this.laotrafuncion() * this.compropro()) / 2;
    } else {
      return (
        (this.props.ancpan - this.compruebafuncion() * this.compropro()) / 2
      );
    }
  }
  compruebafuncion = () => {
    var valor = 1680 + 265400 / this.props.altpan;
    return valor;
  };
  laotrafuncion = () => {
    var valor = 1710 + (550000 / this.props.ancpan - 20);
    return valor;
  };
  Stilointeractivo() {
    return {
      marginTop: this.comprobaltu() + this.lalturesponsive() + "px",
      marginLeft: this.comprobanch() + this.borde() + "px",
      transform:
        "scale(" + this.escala() + ") rotate(" + this.props.rotat + "deg)",
      zIndex: this.props.indice,
      filter:
        "drop-shadow( " +
        this.props.movx +
        "px " +
        this.props.movy +
        "px " +
        this.props.clar +
        "px rgba(0, 0, 0, .5))",
    };
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="soyimg shadow"
        style={this.Stilointeractivo()}
      >
        <title>{this.props.ident.nombre}</title>

        <g id={this.props.ident.nombre} data-name={this.props.ident.ID}>
          <g id={this.props.ident.nombre + "esc"}>
            <path className={this.props.col} d={this.props.ident.path} />
          </g>
        </g>
      </svg>
    );
  }
}

export default Contrusvj;
