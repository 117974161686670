import React, { Component } from "react";

class Respuesta extends Component {
  recibeimagen() {
    var nombre = "/iconos/" + this.props.urls;
    return nombre;
  }

  render() {
    return (
      <div
        className="cuerpopre"
        onClick={() => this.props.activa(this.props.ident)}
      >
        <div className="laimagen">
          <img
            src={this.recibeimagen()}
            className="soylaimagen"
            alt={this.props.valor}
          ></img>
        </div>
        <div className="eltexto">{this.props.valor}</div>
      </div>
    );
  }
}
export default Respuesta;
