import React, {Component} from 'react';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';


import Checkinbox from './checkinbox';


import '../estilos/carga.css';

class Elback extends Component{
    state = {
        entra:true,
        lapas:'',
        elarray:'',
        eltit:'',
        datostra:[],
        proces:[],
        des:true,
        actboproc:[-1,-1],
        ventacotprend:false,
        texchat:[],
        carga:false,
        envinf:'',
        enviatexto:'',
        agreg:false,
        verid:'',
        verurl:'',
        vermens:'',
        vername:'',
    }
    envinf= e =>{
        if(e){
            e.preventDefault();
        }
        var elden = { dequien : this.props.logueado,
            lapas : this.state.lapas,
            eltit : this.state.eltit }
        axios.post('https://api.desenfrenadonetworks.com/llamame/danfo', elden)
        .then(response => {
            this.setState({ proces : response.data});
        })
        .catch(function (error) {
        });
    }
    handleChange = e =>{
        this.setState({[e.target.name]:e.target.value})
    }
    handleChanges = e =>{
        var valor = e.target.value.replace(/\n/g, ' ');
        this.setState({[e.target.name]:valor})
    }

    cuandosuba = (e) =>{
        this.setState({entra:false});
        e.preventDefault();
    }

    eldes = () =>{
        this.setState({entra:false, des :false})
    }
    envmensaje =() =>{
        this.setState({carga:true})
        var eleme = this.state.enviatexto.replace(/ /g, '');
        if(eleme !== ''){
            var lascoti = {elmen : this.state.enviatexto, 
                dequien : this.props.logueado,
                aquien : this.state.proces[this.state.actboproc[0]].entregas[this.state.actboproc[1]].ID,
                lacot : this.state.eltit
            }
            axios.post('https://api.desenfrenadonetworks.com/llamame/envmensaje', lascoti)
            .then(response => {
               this.envinf();
                setTimeout(() => {
                    this.traetextomensajes();
                    this.setState({enviatexto:'',carga:false});
                }, 4000);
                
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
    agreganuev = (e) =>{
    
        e.preventDefault();
        var eleme = this.state.vermens.replace(/ /g, '');
        if(eleme !== '' && parseInt(this.state.verid) < this.state.proces.length){
            var elden = {
                dequien : this.props.logueado,
                lapas : this.state.lapas,
                acual : this.state.proces[this.state.verid].fecha[0].ID,
                nombre : this.state.vername,
                laurl : this.state.verurl,
                mensaje: this.state.vermens}
            axios.post('https://api.desenfrenadonetworks.com/llamame/agnuevo', elden)
            .then(response => {
                this.envinf();
                this.actlas(false);
            })
            .catch(function (error) {
            });
        }
    }


    cuandosubare = (e) =>{
        this.setState({entra:false});
        e.preventDefault();
        var elden = {
            lapas : this.state.lapas,
            elarray : this.state.elarray,
            idio:this.props.idio}
        axios.post('https://api.desenfrenadonetworks.com/llamame/muestra', elden)
        .then(response => {
            this.setState({ datostra : response.data});
        })
        .catch(function (error) {
        });
    }
    format = (datos) =>{
        var num = datos;
            if(!isNaN(num)){
                num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                num = num.split('').reverse().join('').replace(/^\./,'');
                return num;
            }  
        }
    traeloaspreyres = ()=>{
        var respues = [];
        for(var i = 1 ; i < this.state.datostra.length ; i++){
            respues.push(<div className="textotimipe" key={'a' + i}>{i}. {this.state.datostra[i][0][this.props.idio]}</div>)
            respues.push(<div className="contenidoperfil" key={'b'+i}> {this.state.datostra[i][1][this.props.idio]}</div>)
        }
        return respues;
    }

    determinactivo = (laubic) =>{
        if(this.state.actboproc.toString() === laubic.toString()){
            return true;
        }else{
            return false;
        }
    }

    eldelosclicks = (aruno, ardos) =>{
        return <div className="listadeentr verla"  onClick={()=>this.clickcheentre([aruno,ardos])} key={'botons'+ aruno + ardos}>
            <Checkinbox boleano={this.determinactivo([aruno,ardos])} /> <div className="pequemar">{this.state.proces[aruno].entregas[ardos].fecha} {this.state.proces[aruno].entregas[ardos].name}</div>
        <div className="tapatodo"></div></div>
    }

    traeproces = () =>{
        var regres = ['loading...']
        if(this.state.proces.length >0){
            regres = []
            for(var i = 0; i < this.state.proces.length; i++){
                
                regres.push(<div className='textotimipe conboton alaiz' key={"fech"+i}>
                                <div><i className="fas fa-caret-square-down"></i> {i}: {this.state.proces[i].fecha[0].fecha} {this.state.proces[i].fecha[0].objetivo}</div>
                                {this.veriboton(4,i)}
                            </div>);
                var segunrray = [];
                for( var j=0; j < this.state.proces[i].entregas.length;j++){
                    segunrray.push(this.eldelosclicks(i,j))
                }
                regres.push(
                    <div className=" ajustatext agranpoco"  key={'result'+i}>
                        {segunrray}
                    </div>
                )
            }
        }
        
        return regres;
    }


    ele=() =>{
        return {
            top:this.state.ventacotprend ? '0' :'130%',
        }
    }

    ese=() =>{
        return {
            bottom:this.state.ventamesa ? null :'-200vh',
        }
    }
    eve = () =>{
        if(this.state.carga){
            return 'fas fa-spinner iconoenviar giragira'
        }else{
            return 'fas fa-paper-plane iconoenviar'
        }

    }

    muestralapanta = (datus) =>{
        this.setState({ventacotprend:datus})
    }

    veriboton = (val ,miconos) =>{
        if(val===4 && miconos===this.state.actboproc[0]){
            return <div className="vanlosiconos">
            <div className="iconopuntual" 
            onClick={()=>{this.muestralapanta(true);window.open(this.state.proces[this.state.actboproc[0]].entregas[this.state.actboproc[1]].ubicarchivo);}}>
                <i className="far fa-eye"></i>
                <div className="textoicono">{this.props.eltext(150)}</div>
            </div>
            <div className="iconopuntual" onClick={()=>{this.activetamesa(true);this.traetextomensajes()}}>
                <i className="far fa-comment-dots"></i>
                <div className="textoicono">{this.props.eltext(151)}</div>
            </div>
        </div>
        }
    }


    traetextomensajes = () =>{
        var clien = [];
        if(this.state.actboproc[0]!==-1){
            var sring = this.state.proces[this.state.actboproc[0]].entregas[this.state.actboproc[1]].acomentusu;
            clien = sring.split("$");
            if(clien[0] !==""){
                for(var i=0;i<clien.length;i++){
                    clien[i]= JSON.parse(clien[i]);
                }
            }
            
            this.setState({texchat:[clien]});
            setTimeout(() => {
                var objDiv = document.getElementsByClassName('conchat');
                objDiv[0].scrollTop = objDiv[0].scrollHeight;
            }, 500);
            
        }
    }

    clickcheentre = ( group ) =>{
        if(this.state.actboproc.toString() === group.toString()){
            this.setState({actboproc:[-1,-1]});
        }else{
            this.setState({actboproc:group});
        }
    }
    eme=() =>{
        return {
            top:this.state.ventamesa ? '0' :'130%',
        }
    }
    efe=() =>{
        return {
            top:this.state.agreg ? '0' :'130%',
        }
    }


    activetamesa = (valor) =>{
        this.setState({ventamesa:valor})
    }
    actlas = (valor) =>{
        this.setState({agreg:valor})
    }


    
    mandalid = (elv , seg) =>{
        if((elv-1) === seg){return 'u'}
    }


    imprimechat = () =>{
        var tiempo='';
        var total = [];
        if(this.state.texchat[0] !== undefined && this.state.texchat[0][0] !== '')
        {
          var laclas='';
          for(var i = 0 ; i<this.state.texchat[0].length;i++){
              if(i===0 || this.state.texchat[0][i].date !== this.state.texchat[0][i-1].date){
                  total.push(<div className="day" key={"time"+i}>{this.state.texchat[0][i].date}</div>)
              }
              if(this.state.texchat[0][i].l === "b"){
                 laclas = 'other';
              }else{
                 laclas = 'self';
              }
              tiempo = this.state.texchat[0][i].time.split(':');

              total.push(
                <div className={laclas} key={'nous'+i} id={this.mandalid(this.state.texchat[0].length,i)}>
                            <div className="msg">
                                  <p dangerouslySetInnerHTML={{__html: this.state.texchat[0][i].mens}}>
                                  </p>
                                  <time>{tiempo[0] + ":" + tiempo[1]}</time>
                            </div>
                </div>

              );
          }
    
        }
       return total;
    }



    render =() =>{
        if(this.state.datostra.length === 0 ){
            if(this.state.entra){
                return <form className="preguntaquienes" onSubmit={this.cuandosuba}>
                 <input type="password" className ="ilinput" 
                    onChange={this.handleChange}
                    value={this.state.lapas }
                    placeholder={this.props.eltext(7)} required 
                    name="lapas"/>
                    <button type="submit" className ="elboton marabajo">{this.props.eltext(49)}</button>
                    <div className ="elboton" onClick={this.eldes}><i className="fas fa-ankh"></i></div>
              </form>
            }else{
                if(this.state.proces.length === 0 ){
                    if(this.state.des){
                        return <form className="preguntaquienes" onSubmit={this.cuandosubare}>
                            <input type="text" className ="ilinput" 
                                onChange={this.handleChange}
                                value={this.state.elarray } required 
                                placeholder={this.props.eltext(143)}
                                name="elarray"/>
                                <button type="submit" className ="elboton">{this.props.eltext(49)}</button>
                        </form>
                    }else{
                        return <form className="preguntaquienes" onSubmit={this.envinf}>
                            <input type="text" className ="ilinput" 
                                onChange={this.handleChange}
                                value={this.state.eltit } required 
                                placeholder={this.props.eltext(16)}
                                name="eltit"/>
                                <button type="submit" className ="elboton">{this.props.eltext(49)}</button>
                        </form>
                    }

                }else{
                    return(
                    <div className="cuerpo">
                        <div className="titulopres">
                            <div className="marabajo">
                        
                            {this.traeproces()}
                            <div className ="elboton" onClick={()=>this.actlas(true)}><i className="fas fa-plus"></i></div>
                            
                            <div className="flotanteexplic quitahe alervisu verla" 
                            style={this.efe()} >
                                <div className='tapatodo verla' onClick={()=>this.actlas(false)}></div>
                                <form className="preguntaquienes" onSubmit={this.agreganuev}>
                                    <input type="number" className ="ilinput realtivo" 
                                    onChange={this.handleChange}
                                    value={this.state.verid } required 
                                    placeholder='num entrega'
                                    name="verid"/>
                                    <input type="text" className ="ilinput realtivo" 
                                    onChange={this.handleChange}
                                    value={this.state.verurl } required 
                                    placeholder={this.props.eltext(161)}
                                    name="verurl"/>
                                    <input type="text" className ="ilinput realtivo" 
                                    onChange={this.handleChange}
                                    value={this.state.vername } required 
                                    placeholder={this.props.eltext(16)}
                                    name="vername"/>
                                    <TextareaAutosize type="text" className ="ilinput latextarea realtivo" 
                                        minRows={1}
                                        maxRows={2}
                                        maxLength={600}
                                        name="vermens"
                                        onChange={this.handleChanges}
                                        value={this.state.vermens}
                                        placeholder={this.props.eltext(153)} required/>

                                    <button type="submit" className ="elboton realtivo">{this.props.eltext(49)}</button>
                                </form>
                            </div>


                            <div className="flotanteexpli quitahe alervisu verla" 
                            style={this.ele()} 
                            dangerouslySetInnerHTML={{__html: this.props.eltext(152)}}
                            onClick={()=>this.muestralapanta(false)}>
                            </div>
            
                            <div className="flotanteexplic quitahe alervisu" style={this.eme()}>
                                <div className='tapatodo verla' onClick={()=>this.activetamesa(false)}></div>
                                <div className="conchat chat">
                                        {this.imprimechat()}  
            
                                        <div className="ventachat" style={this.ese()}>
                                        <TextareaAutosize type="text" className ="ilinput latextarea quitarmar" 
                                        minRows={1}
                                        maxRows={2}
                                        maxLength={600}
                                        name="enviatexto"
                                        onChange={this.handleChanges}
                                        value={this.state.enviatexto}
                                        placeholder={this.props.eltext(153)} 
                                        onClick={()=>setTimeout(() => {
                                            var objDiv = document.getElementsByClassName('conchat');
                                            objDiv[0].scrollTop = objDiv[0].scrollHeight;
                                        }, 500)}/>
                                        <i className={this.eve()} onClick={()=>{if(!this.state.carga)this.envmensaje()}}></i>
                                        </div>
                                </div>
                                <div className="xinservible" onClick={()=>{this.envinf();  
                                setTimeout(() => {
                                    this.traetextomensajes();
                                }, 4000);}}><i className="fas fa-redo"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>)
                     
                }
            }
        }else{
            return(
            <div className="cuerpo">
                <div className='titulopres'> 
                    <div className ='marabajo'>
                        <div className="textotimipe">Total : ${this.format(this.state.datostra[0])}</div>
                        <div className="contenidoperfil"></div>
                        {this.traeloaspreyres()}
                    </div>
                </div>
            </div>
            )
        }
        
          
        
    }
}
export default Elback;