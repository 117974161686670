import React, { Component } from "react";

import Contrusvj from "./contrusvj";

class Imagenes extends Component {
  state = {
    x: 0,
    contr: [
      { val: 1 },
      { val: 2.3 },
      { val: 3.6 },
      { val: 4.9 },
      { val: 6.2 },
      { val: 7.5 },
      { val: 8.8 },
      { val: 9 },
    ],
  };

  enviarobj(
    id,
    posx = 1,
    posy = 1,
    col = "cls-1",
    zinde = -2,
    rotat = 0,
    clar = 4,
    movx = 8,
    movy = -16,
    esc = "1"
  ) {
    for (var i = 0; i < this.props.apiResponse.length; i++) {
      let imagencita = [];
      if (id === this.props.apiResponse[i].ID) {
        imagencita.push(
          <Contrusvj
            ident={this.props.apiResponse[i]}
            col={col}
            altpan={this.props.alto}
            ancpan={this.props.ancho}
            rotat={rotat}
            posx={posx}
            posy={posy}
            esc={esc}
            indice={zinde}
            key={this.props.apiResponse[i].ID}
            movy={movy}
            movx={movx}
            clar={clar}
          />
        );
        return imagencita;
      }
    }
  }

  stilointerac = () => {
    return {
      top: this.props.cambio ? "200vh" : null,
    };
  };

  zonas = (val, cont) => {
    var total = val + (1 + this.props.lax * this.state.contr[cont].val);
    return total;
  };

  animalun = () => {
    if (this.props.animales === 1) {
      return (
        <div>
          {this.enviarobj(7, this.zonas(20, 4), 75, "cls-2", -3)}
          {this.enviarobj(8, this.zonas(28, 4), 72, "cls-2", -3)}
          {this.enviarobj(9, this.zonas(35, 4), 67, "cls-2", -3)}
        </div>
      );
    } else if (this.props.animales === 2) {
      return (
        <div>
          {this.enviarobj(19, this.zonas(26, 1), 56, "cls-2", -4)}
          {this.enviarobj(11, this.zonas(35, 1), 58, "cls-2", -2)}
        </div>
      );
    } else {
      return (
        <div>
          {this.enviarobj(6, this.zonas(68, 6), 60, "cls-2", -2)}
          {this.enviarobj(10, this.zonas(54, 6), 62, "cls-2", -2)}
          {this.enviarobj(12, this.zonas(47, 6), 65, "cls-2", -2)}
        </div>
      );
    }
  };

  animaldos = () => {
    if (this.props.animales === 1) {
      return (
        <div>
          {this.enviarobj(29, this.zonas(54, 2), 49, "cls-2", -5)}
          {this.enviarobj(32, this.zonas(60, 2), 70, "cls-2", -5)}
        </div>
      );
    } else if (this.props.animales === 2) {
      return (
        <div>
          {this.enviarobj(31, this.zonas(8, 6), 60, "cls-2", -1)}
          {this.enviarobj(28, this.zonas(73, 6), 55, "cls-2", -1)}
        </div>
      );
    } else {
      return (
        <div>
          {this.enviarobj(30, this.zonas(30, 3), 54, "cls-2", -5)}
          {this.enviarobj(35, this.zonas(28, 3), 64, "cls-2", -5)}
          {this.enviarobj(36, this.zonas(25, 3), 64, "cls-2", -5)}
          {this.enviarobj(35, this.zonas(22, 3), 64, "cls-2", -5)}
        </div>
      );
    }
  };
  animaltes = () => {
    if (this.props.animales === 1) {
      return (
        <div>
          {this.enviarobj(48, this.zonas(65, 5), 4, "cls-2", -1)}
          {this.enviarobj(45, this.zonas(32, 5), 89, "cls-2", -2)}
          {this.enviarobj(56, this.zonas(20, 5), 88, "cls-2", -2)}
          {this.enviarobj(57, this.zonas(12, 5), 80, "cls-2", -2)}
        </div>
      );
    } else if (this.props.animales === 2) {
      return (
        <div>
          {this.enviarobj(47, this.zonas(80, 4), 71, "cls-2", -2)}
          {this.enviarobj(7, this.zonas(27, 2), 45, "cls-2", -3)}
        </div>
      );
    } else {
      return (
        <div>
          {this.enviarobj(46, this.zonas(48, 4), 57, "cls-2", -1)}
          {this.enviarobj(51, this.zonas(50, 4), 30, "cls-2", -2)}
        </div>
      );
    }
  };
  animalcua = () => {
    if (this.props.animales === 1) {
      return (
        <div>
          {this.enviarobj(60, this.zonas(21, 2), 30, "cls-2", -2)}

          {this.enviarobj(66, this.zonas(69, 4), 47, "cls-2", -2)}
        </div>
      );
    } else if (this.props.animales === 2) {
      return (
        <div>
          {this.enviarobj(68, this.zonas(8, 4), 50, "cls-2", -2)}
          {this.enviarobj(61, this.zonas(36, 4), 68, "cls-2", -2)}

          {this.enviarobj(60, this.zonas(51, 5), 83, "cls-2", -2)}
        </div>
      );
    } else {
      return (
        <div>
          {this.enviarobj(89, this.zonas(20, 5), 86, "cls-2", -2)}
          {this.enviarobj(67, this.zonas(51, 2), 35, "cls-2", -2)}
        </div>
      );
    }
  };
  animalcinca = () => {
    if (this.props.animales === 1) {
      return (
        <div>
          {this.enviarobj(69, this.zonas(83, 3), 48, "cls-2", -5)}
          {this.enviarobj(70, this.zonas(71, 3), 48, "cls-2", -5)}
        </div>
      );
    } else if (this.props.animales === 2) {
      return (
        <div>
          {this.enviarobj(80, this.zonas(9, 6), 55, "cls-2", -5)}
          {this.enviarobj(81, this.zonas(21, 6), 40, "cls-2", -5)}
          {this.enviarobj(82, this.zonas(31, 6), 20, "cls-2", -5)}
        </div>
      );
    } else {
      return (
        <div>
          {this.enviarobj(77, this.zonas(38, 4), 59, "cls-2", -5)}
          {this.enviarobj(78, this.zonas(57, 4), 70, "cls-2", -5)}
          {this.enviarobj(79, this.zonas(68, 4), 68, "cls-2", -5)}
        </div>
      );
    }
  };

  render() {
    if (this.props.paso) {
      if (this.props.escen === 1) {
        return (
          <div className="bloquevisu" style={this.stilointerac()}>
            {this.enviarobj(26, 15, 7, "cls-2", -5, 0, 2, 5, -3)}
            {this.enviarobj(20, this.zonas(23, 0), 4, "cls-2", -5, 0, 0, 0, 0)}
            {this.enviarobj(14, this.zonas(22, 0), 17, "cls-2", -5, 0, 0, 0, 0)}
            {this.enviarobj(13, this.zonas(40, 0), 30, "cls-2", -5, 0, 0, 0, 0)}

            {this.enviarobj(23, this.zonas(11, 0), 23, "cls-3", -5, 0, 16)}
            {this.enviarobj(17, this.zonas(11.1, 1), 54, "cls-1", -4, 0, 16)}
            {this.enviarobj(16, this.zonas(11, 3), 59, "cls-4", -3, 0, 16)}
            {this.enviarobj(15, this.zonas(10.7, 5), 65, "cls-5", -2, 0, 16)}

            {this.enviarobj(3, this.zonas(10, 2), 36, "cls-1", -4)}
            {this.enviarobj(2, this.zonas(13, 2), 37, "cls-1", -3)}
            {this.enviarobj(1, this.zonas(15, 2), 52, "cls-1", -3)}
            {this.enviarobj(25, this.zonas(15, 2), 58, "cls-4", -4)}

            {this.enviarobj(3, this.zonas(40, 2), 26, "cls-1", -4)}
            {this.enviarobj(2, this.zonas(46, 2), 27, "cls-1", -3, 30)}
            {this.enviarobj(1, this.zonas(42, 2), 42, "cls-1", -3)}
            {this.enviarobj(25, this.zonas(45, 2), 48, "cls-4", -4)}

            {this.enviarobj(3, this.zonas(75, 4), 36, "cls-1", -2)}
            {this.enviarobj(2, this.zonas(79, 4), 37, "cls-1", -2)}
            {this.enviarobj(1, this.zonas(82, 4), 52, "cls-1", -2)}
            {this.enviarobj(25, this.zonas(80, 4), 57, "cls-4", -3)}

            {this.enviarobj(5, this.zonas(65, 5), 5, "cls-6", -2)}
            {this.enviarobj(24, this.zonas(70, 5), 30, "cls-4", -2)}

            {this.enviarobj(21, this.zonas(60, 5), 30, "cls-4", -2)}
            {this.enviarobj(4, this.zonas(53, 5), 10, "cls-5", -2, 0)}

            {this.animalun()}
          </div>
        );
      } else if (this.props.escen === 2) {
        return (
          <div className="bloquevisu" style={this.stilointerac()}>
            {this.enviarobj(26, 51, 16, "cls-2", -5, 0, 2, 5, 2)}
            {this.enviarobj(14, this.zonas(-7, 0), 10, "cls-2", -5, 0, 0, 0, 0)}

            {this.enviarobj(34, this.zonas(6, 0), 8, "cls-5", -5, 0, 16)}
            {this.enviarobj(33, this.zonas(14, 1), 35, "cls-7", -5, 0, 0, 0, 0)}
            {this.enviarobj(44, this.zonas(6, 1), 16.5, "cls-5", -5, 0, 16)}
            {this.enviarobj(41, this.zonas(6, 3), 50, "cls-4", -4, 0, 16)}
            {this.enviarobj(40, this.zonas(6, 4), 60, "cls-3", -3, 0, 16)}
            {this.enviarobj(39, this.zonas(6, 6), 75, "cls-5", -2, 0, 16)}

            {this.enviarobj(43, this.zonas(19, 1), 33, "cls-5", -5)}
            {this.enviarobj(38, this.zonas(17, 1), 18, "cls-3", -5)}
            {this.enviarobj(43, this.zonas(12, 1), 43, "cls-5", -5)}
            {this.enviarobj(38, this.zonas(10, 1), 28, "cls-3", -5)}
            {this.enviarobj(43, this.zonas(31, 1), 41, "cls-5", -5)}
            {this.enviarobj(38, this.zonas(29, 1), 26, "cls-3", -5)}

            {this.enviarobj(42, this.zonas(72, 6), 32, "cls-5", -1)}
            {this.enviarobj(27, this.zonas(56, 6), -35, "cls-3", -1)}

            {this.animaldos()}
          </div>
        );
      } else if (this.props.escen === 3) {
        return (
          <div className="bloquevisu" style={this.stilointerac()}>
            {this.enviarobj(26, 17, 7, "cls-2", -5, 0, 2, 5, -3)}
            {this.enviarobj(20, this.zonas(25, 0), 4, "cls-2", -5, 0, 0, 0, 0)}
            {this.enviarobj(14, this.zonas(14, 0), 17, "cls-2", -5, 0, 0, 0, 0)}
            {this.enviarobj(13, this.zonas(42, 0), 30, "cls-2", -5, 0, 0, 0, 0)}

            {this.enviarobj(50, this.zonas(6, 1), 10, "cls-3", -5, 0, 16)}
            {this.enviarobj(49, this.zonas(6, 2), 30, "cls-5", -4, 0, 16)}
            {this.enviarobj(55, this.zonas(6, 3), 38, "cls-4", -2, 0, 16)}
            {this.enviarobj(53, this.zonas(6, 5), 73, "cls-3", -1, 0, 16)}

            {this.enviarobj(43, this.zonas(34, 1), 25, "cls-5", -5)}
            {this.enviarobj(38, this.zonas(32, 1), 10, "cls-3", -5)}

            {this.enviarobj(52, this.zonas(10, 6), -10, "cls-3", -1)}
            {this.enviarobj(
              52,
              this.zonas(22, 2),
              -10,
              "cls-3",
              -3,
              0,
              4,
              8,
              -16,
              "0.6"
            )}
            {this.enviarobj(
              88,
              this.zonas(52, 2),
              -10,
              "cls-3",
              -3,
              0,
              4,
              -8,
              -16
            )}

            {this.enviarobj(58, this.zonas(67, 3), -3, "cls-3", -2)}
            {this.enviarobj(87, this.zonas(66, 3), -30, "cls-4", -3)}

            {this.animaltes()}
          </div>
        );
      } else if (this.props.escen === 4) {
        return (
          <div className="bloquevisu" style={this.stilointerac()}>
            {this.enviarobj(26, 15, 7, "cls-2", -5, 0, 2, 5, -3)}
            {this.enviarobj(20, this.zonas(24, 0), 4, "cls-2", -5, 0, 0, 0, 0)}
            {this.enviarobj(14, this.zonas(23, 0), 17, "cls-2", -5, 0, 0, 0, 0)}
            {this.enviarobj(13, this.zonas(41, 0), 30, "cls-2", -5, 0, 0, 0, 0)}

            {this.enviarobj(62, this.zonas(3.1, 6), 80, "cls-6", -1, 0, 16)}
            {this.enviarobj(63, this.zonas(3.1, 5), 67, "cls-5", -2, 0, 16)}
            {this.enviarobj(64, this.zonas(3.1, 3), 43, "cls-3", -3, 0, 16)}
            {this.enviarobj(65, this.zonas(3.1, 1), 23, "cls-7", -4, 0, 16)}

            {this.enviarobj(59, this.zonas(5, 6), 70, "cls-7", -2, 0, 16)}
            {this.enviarobj(59, this.zonas(7, 6), 76, "cls-7", -2, 24, 16)}

            {this.animalcua()}
          </div>
        );
      } else if (this.props.escen === 5) {
        return (
          <div className="bloquevisu" style={this.stilointerac()}>
            {this.enviarobj(26, 15, 7, "cls-2", -5, 0, 2, 5, -3)}
            {this.enviarobj(20, this.zonas(24, 0), 4, "cls-2", -5, 0, 0, 0, 0)}
            {this.enviarobj(14, this.zonas(23, 0), 17, "cls-2", -5, 0, 0, 0, 0)}
            {this.enviarobj(13, this.zonas(41, 0), 30, "cls-2", -5, 0, 0, 0, 0)}

            {this.enviarobj(74, this.zonas(6, 1), 25, "cls-5", -5, 0, 16)}
            {this.enviarobj(73, this.zonas(37, 1), 7, "cls-4", -5, 0, 16)}
            {this.enviarobj(76, this.zonas(6, 2), 32, "cls-3", -5, 0, 16)}
            {this.enviarobj(72, this.zonas(6, 3), 37, "cls-7", -5, 0, 16)}

            {this.enviarobj(75, this.zonas(6, 3), 57, "cls-5", -5, 0, 16)}

            {this.enviarobj(90, this.zonas(29.5, 3), 38, "cls-4", -5, 0, 16)}

            {this.enviarobj(83, this.zonas(71, 5), 85, "cls-4", -5)}
            {this.enviarobj(84, this.zonas(76, 5), 89, "cls-5", -5)}

            {this.enviarobj(83, this.zonas(11, 4), 65, "cls-4", -5)}
            {this.enviarobj(84, this.zonas(16, 4), 75, "cls-5", -5)}

            {this.enviarobj(71, this.zonas(6, 6), 86, "cls-3", -5, 0, 16)}
            {this.enviarobj(86, this.zonas(66, 3), -49, "cls-3", -5)}

            {this.animalcinca()}
          </div>
        );
      }
    } else {
      return <div></div>;
    }
  }
}
export default Imagenes;
