import React, { Component } from "react";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";

class Asesoper extends Component {
  state = {
    eltotal: 0,
    pant: 1,
    veriacti: false,
    resp: "",
    telefono: "",
    email: "",
    cuando: "",
    hora: "",
    extamensa: "",
    carga: false,
  };
  stilointeractiv = () => {
    return {
      opacity: this.props.vacamb ? 0 : 1,
    };
  };
  abretex = (id) => {
    var obj = this.props.content[id - 1];
    if (obj === undefined) {
      return "cargando...";
    } else {
      var valores = Object.values(obj);
      return valores[1];
    }
  };
  cambioinputco = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  stiloint = () => {
    return {
      display: this.state.veriacti ? "flex" : "none",
    };
  };
  cambiarpant = (val) => {
    this.setState({ pant: val, veriacti: false });
  };

  cuandosubaco = (e) => {
    this.setState({ carga: true });
    e.preventDefault();
    var obj = {
      telefono: this.state.telefono,
      cuando: this.state.cuando,
      hora: this.state.hora,
      coti: this.props.total,
    };
    axios
      .post("https://api.desenfrenadonetworks.com/llamame", obj)
      .then((response) => {
        if (response.data[0].sisas) {
          this.cambiarpant(2);
          this.setState({ carga: false });
        } else {
          this.setState({ resp: this.abretex(31) });
          this.setState({ veriacti: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  cuandosubaema = (e) => {
    this.setState({ carga: true });
    e.preventDefault();
    var obj = {
      email: this.state.email,
      extamensa: this.state.extamensa,
      coti: this.props.total,
    };
    axios
      .post("https://api.desenfrenadonetworks.com/llamame/contactme", obj)
      .then((response) => {
        if (response.data[0].sisas) {
          this.cambiarpant(2);
          this.setState({ carga: false });
        } else {
          this.setState({ resp: this.abretex(31) });
          this.setState({ veriacti: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  stacargando = (def) => {
    if (def) {
      return {
        display: this.state.carga ? "none" : "block",
      };
    } else {
      return {
        display: this.state.carga ? "block" : "none",
      };
    }
  };
  cambpantalla = (dato) => {
    this.setState({ pant: dato });
  };

  render = () => {
    if (this.state.pant === 1) {
      return (
        <div className="pregunlogin" style={this.stilointeractiv()}>
          <div className="eltitu">{this.abretex(166)}</div>

          <div
            className="elboton"
            onClick={() => {
              this.cambpantalla(3);
            }}
          >
            <i className="far fa-phone-alt"></i>&nbsp;{this.abretex(167)}
          </div>
          <div
            className="elboton subale"
            onClick={() => {
              this.cambpantalla(4);
            }}
          >
            <i className="far fa-envelope-open-text"></i>&nbsp;
            {this.abretex(168)}
          </div>
        </div>
      );
    } else if (this.state.pant === 2) {
      return (
        <div className="pregunlogin" style={this.stilointeractiv()}>
          <div className="eltitu">{this.abretex(38)}</div>
          <div
            type="submit"
            className="elboton subale"
            onClick={() => window.location.replace("../")}
          >
            {this.abretex(39)}
          </div>
        </div>
      );
    } else if (this.state.pant === 3) {
      return (
        <form
          onSubmit={this.cuandosubaco}
          className="pregunlogin"
          style={this.stilointeractiv()}
        >
          <div className="eltitu">{this.abretex(32)}</div>
          <div className="error" style={this.stiloint()}>
            {this.state.resp}
          </div>
          <div className="aumentapaddi centratex sinpunter">
            {this.abretex(33)}
          </div>
          <input
            type="number"
            placeholder={this.abretex(32)}
            onChange={this.cambioinputco}
            value={this.state.telefono}
            name="telefono"
            required
            className="ilinput"
          />

          <div className="aumentapaddi centratex sinpunter">
            {this.abretex(34)}
          </div>
          <input
            type="date"
            placeholder={this.abretex(35)}
            onChange={this.cambioinputco}
            value={this.state.cuando}
            name="cuando"
            required
            className="ilinput"
          />

          <input
            type="time"
            placeholder={this.abretex(36)}
            onChange={this.cambioinputco}
            value={this.state.hora}
            name="hora"
            required
            className="ilinput"
          />
          <button type="submit" className="elboton" disabled={this.state.carga}>
            <i
              style={this.stacargando(false)}
              className="fas fa-sync-alt giragira"
            ></i>
            <div style={this.stacargando(true)}>{this.abretex(49)}</div>
          </button>
        </form>
      );
    } else if (this.state.pant === 4) {
      return (
        <form
          onSubmit={this.cuandosubaema}
          className="pregunlogin"
          style={this.stilointeractiv()}
        >
          <div className="eltitu">{this.abretex(169)}</div>
          <div className="error" style={this.stiloint()}>
            {this.state.resp}
          </div>
          <div className="aumentapaddi centratex sinpunter">
            {this.abretex(33)}
          </div>
          <input
            type="email"
            placeholder={this.abretex(17)}
            onChange={this.cambioinputco}
            value={this.state.email}
            name="email"
            required
            className="ilinput"
          />
          <div className="aumentapaddi centratex sinpunter">
            {this.abretex(170)}
          </div>
          <TextareaAutosize
            type="text"
            className="ilinput latextarea "
            minRows={2}
            maxLength={600}
            maxRows={6}
            name="extamensa"
            onChange={this.cambioinputco}
            value={this.state.extamensa}
            placeholder={this.abretex(57)}
          />
          <button type="submit" className="elboton" disabled={this.state.carga}>
            <i
              style={this.stacargando(false)}
              className="fas fa-sync-alt giragira"
            ></i>
            <div style={this.stacargando(true)}>{this.abretex(49)}</div>
          </button>
        </form>
      );
    }
  };
}
export default Asesoper;
