import React, { Component } from "react";

import { Link } from "react-router-dom";

class Alerta extends Component {
  state = {
    labro: true,
  };
  muestratesatanas = () => {
    if (this.state.labro) {
      document.body.style.overflow = "hidden";
      return {
        top: "0",
      };
    } else {
      document.body.style.overflow = "visible";
      document.body.style.overflowX = "hidden";
      return {
        top: "110vh",
      };
    }
  };

  elsatanas = (dat) => {
    this.setState({ labro: dat });
  };

  render = () => {
    if (this.props.eltext(172) === "elink") {
      return (
        <div className="alert" style={this.muestratesatanas()}>
          <div className="tapatodo" onClick={() => this.elsatanas(false)}></div>
          <div className="flotalert" onClick={() => this.elsatanas(false)}>
            {this.props.eltext(171)}
            <Link
              to="/perso"
              className="elboton subale"
              onClick={() => this.elsatanas(false)}
            >
              {this.props.eltext(2)}
            </Link>
            <div className="xinservible" onClick={() => this.elsatanas(false)}>
              <i className="fas fa-times"></i>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="alert" style={this.muestratesatanas()}>
          <div className="tapatodo" onClick={() => this.elsatanas(false)}></div>
          <Link
            to="/menssage"
            className="subale realtivo"
            onClick={() => this.elsatanas(false)}
          >
            <img
              src={this.props.eltext(172)}
              className="imageninti"
              alt="promoción actual desenfrenado network"
            />
          </Link>
          <div className="xinservible" onClick={() => this.elsatanas(false)}>
            <i className="fas fa-times"></i>
          </div>
        </div>
      );
    }
  };
}
export default Alerta;
