import React, { Component } from "react";

class Compedita extends Component {
  estiloint = () => {
    return {
      filter: this.props.verifactivo ? "none" : "opacity(50%)",
    };
  };
  estilointa = (val) => {
    var dat = "none";
    if (this.props.langua === "esp" && val === 1) dat = "opacity(50%)";
    if (this.props.langua === "eng" && val === 2) dat = "opacity(50%)";
    if (this.props.langua === "fra" && val === 3) dat = "opacity(50%)";
    return {
      filter: dat,
    };
  };
  traeselects = () => {
    let obj = this.props.loidio;
    let resp = [],
      val = [];
    if (obj.length !== 0) {
      for (var i = 1; i < obj.length; i++) {
        var icon = "";
        if (obj[i].icon !== "") {
          icon = "(" + obj[i].icon + ")";
        }
        val[i] = (
          <option value={obj[i].identi} key={i}>
            {obj[i][this.props.langua]} {icon}
          </option>
        );
      }
      resp = (
        <select
          id="selecti"
          name="selecti"
          className="ilinput dejaser realtivo"
          value={this.props.moned}
          onChange={(e) => {
            this.props.cambmoned(e);
            if (this.props.reini) {
              window.location.replace("");
            }
          }}
        >
          {val}
        </select>
      );
      return resp;
    }
  };
  verimo = (val) => {
    if (this.props.anch > 800) {
      var imagen = [];
      imagen.push(
        <div className="textodetit" key="1">
          {this.props.eltexto(72)}
        </div>
      );
      imagen.push(
        <div className="contanidi" key="2">
          <div className="traeidioma">
            <div
              className="laimagen realtivo"
              style={this.estiloint()}
              onClick={this.props.activate}
            >
              <img
                src="iconos/fondo.svg"
                className="soylaimagen"
                alt="deactivate"
              ></img>
            </div>
          </div>
        </div>
      );
      return imagen;
    }
  };

  render() {
    return (
      <div className="contflota centrico">
        <div className="textodetit">{this.props.eltexto(70)}</div>
        <div className="contanidi">
          <div className="traeidioma">
            <div
              className="laimagen realtivo"
              style={this.estilointa(1)}
              onClick={() => {
                if (this.props.langua !== "esp") this.props.cambid(1);
              }}
            >
              <img
                src="iconos/esp.svg"
                className="soylaimagen"
                alt="español"
              ></img>
            </div>
          </div>
          <div className="traeidioma">
            <div
              className="laimagen realtivo"
              style={this.estilointa(2)}
              onClick={() => {
                if (this.props.langua !== "eng") this.props.cambid(2);
              }}
            >
              <img
                src="iconos/eng.svg"
                className="soylaimagen"
                alt="english"
              ></img>
            </div>
          </div>
          <div className="traeidioma">
            <div
              className="laimagen realtivo"
              style={this.estilointa(3)}
              onClick={() => {
                if (this.props.langua !== "fra") this.props.cambid(3);
              }}
            >
              <img
                src="iconos/fr.svg"
                className="soylaimagen"
                alt="français"
              ></img>
            </div>
          </div>
        </div>

        <div className="textodetit">{this.props.eltexto(71)}</div>
        <label htmlFor="selecti" className="quitame">
          select
        </label>
        {this.traeselects()}

        {this.verimo()}
      </div>
    );
  }
}
export default Compedita;
