import React, { Component } from "react";

class Landing extends Component {
  state = {
    productos: 0,
    porsubma: 0,
    porproduc: 0,
    girados: 0,
    elquemide: 0,
    laruedita: 0,
    ledioclick: false,
    nusclientes: 0,
    elbook: 0,
    laspreg: 0,
  };
  componentDidMount = () => {
    window.addEventListener("scroll", () => {
      this.estanepantalla(this.state.porsubma, "porsubma", "contesubma");
      this.estanepantalla(this.state.porproduc, "porproduc", "explhacemos");
      this.estanepantalla(this.state.laruedita, "laruedita", "cuerlandino");
      this.estanepantalla(this.state.nusclientes, "nusclientes", "concli");
      this.estanepantalla(this.state.elbook, "elbook", "cuerlandina");
      this.estanepantalla(this.state.laspreg, "laspreg", "cuerlandinu");
    });
    this.empiezamover();
  };
  empiezamover = () => {
    if (this.state.ledioclick === false) {
      if (this.state.laruedita > 10) {
        if (this.state.elquemide < 99) {
          var media = parseInt(this.state.elquemide + 0.25) / 7.5;
          media = parseInt(media) * 25.7;
          this.setState({
            elquemide: this.state.elquemide + 0.25,
            girados: media,
          });
        }
      }
      setTimeout(() => {
        this.empiezamover();
      }, 125);
    }
  };
  stilointerac = () => {
    return {
      opacity: this.props.vacamb ? "0" : "1",
    };
  };

  traetitulo = (elind, estrado) => {
    var texto = this.props.eltext(elind).split("");
    var media = [];
    texto.forEach((element, inde) => {
      media.push(<span key={inde}>{element}</span>);
    });
    var regresa = (
      <div className="titdean" key={elind} style={this.estiralo(estrado)}>
        {media}
      </div>
    );
    return regresa;
  };
  efectmo = (dat) => {
    if (this.state.productos === dat) {
      return {
        backgroundColor: "rgba(242, 242, 242, 1)",
      };
    }
  };

  traedivconicono = () => {
    var icons = [
      "fa-globe",
      "fa-mobile-alt",
      "fa-pencil-ruler",
      "fa-gamepad",
      "fa-dice-d12",
      "fa-head-vr",
    ];

    var canticonos = [[], []];
    icons.forEach((e, i) => {
      var med = 1;
      if (i < 3) {
        med = 0;
      }
      canticonos[med].push(
        <i
          key={i}
          className={"fal " + e + " iconoagran delhover "}
          onMouseMove={() => {
            this.setState({ productos: i });
          }}
          style={this.efectmo(i)}
        ></i>
      );
    });
    var elde = [
      <div className="iconoscon" key={"left"} style={this.opacidadema()}>
        {canticonos[0]}
      </div>,
      <div className="explhacemos" key={"cuerpo"} style={this.opacidadema()}>
        <i className={"fad " + icons[this.state.productos] + " iconoag"}></i>
        <div className="eltidetarland">
          {this.props.eltext(119 + this.state.productos)}
        </div>
        {this.props.eltext(175 + this.state.productos)}
      </div>,
      <div className="iconoscon" key={"right"} style={this.opacidadema()}>
        {canticonos[1]}
      </div>,
    ];

    return elde;
  };
  logosclient = () => {
    var lasima = [
      "alli",
      "almin",
      "berkley",
      "nefe",
      "foodroad",
      "alejonjoli",
      "beaute",
      "motreclocks",
    ];
    var descr = [
      "logo Allianz",
      "logo Almonacid Industries",
      "logo Berkley",
      "logo Nefelibata",
      "logo Food Road",
      "logo alejonjoli",
      "logo beaute",
      "logo montreclocks",
    ];
    var losint = [];
    lasima.forEach((e, i) => {
      losint.push(
        <div className="divdeimlogos" key={i}>
          <img
            src={"./client/" + e + ".svg"}
            alt={descr[i]}
            className="logodeloscli"
          />
        </div>
      );
    });
    return <div className="barradelogos">{losint}</div>;
  };

  estanepantalla = (state, stringstaten, clase) => {
    var image = document.getElementsByClassName(clase)[0];
    if (image !== undefined) {
      var bounding = image.getBoundingClientRect();
      if (
        bounding.left >= 0 &&
        bounding.bottom >= 0 &&
        bounding.top <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        var eldo = 2;
        if (window.innerWidth / window.innerHeight > 1.666) eldo = 2.3;
        var percent = Math.trunc(
          100 - (bounding.bottom * 100) / (window.innerHeight * eldo)
        );
        if (state !== percent) {
          this.setState({ [stringstaten]: percent });
        }
      }
    }
  };
  // muevesubmarin = () => {
  //   var laop = "1";
  //   if (this.state.porsubma < 10 || this.state.porsubma > 90) laop = "0";
  //   return {
  //     transform:
  //       "translateX(" +
  //       -((this.state.porsubma / 100) * (window.innerWidth * 2.6)) +
  //       "px) scale(3)",
  //     opacity: laop,
  //   };
  // };
  opacidadema = () => {
    if (this.state.porproduc > 40) {
      return {
        opacity: "1",
      };
    } else {
      return {
        opacity: "0",
      };
    }
  };
  quitaopade = () => {
    if (this.state.porproduc > 40) {
      return {
        opacity: "0",
      };
    } else {
      return {
        opacity: "1",
      };
    }
  };
  estiralo = (stado) => {
    if (stado > 40) {
      return {
        opacity: "1",
      };
    } else {
      return {
        padding: "10% 30%",
        opacity: "0",
      };
    }
  };
  muevebolita = () => {
    if (this.state.laruedita < 10) {
      return {
        opacity: "0",
      };
    }
  };
  traecirculito = () => {
    var iconosa = [
      "fas fa-bed-bunk blaquiado",
      "fas fa-search-dollar blaquiado",
      "fal fa-alicorn",
      "fal fa-user-astronaut",
      "fal fa-phone-laptop",
      "fal fa-meteor",
      "fal fa-feather-alt",
    ];
    var iconosb = [
      "fal fa-laptop-code",
      "fal fa-helmet-battle",
      "fas fa-user-secret blaquiado",
      "fas fa-tachometer-alt-slowest blaquiado",
      "fas fa-elephant blaquiado",
      "fab fa-wordpress blaquiado",
      "fas fa-computer-classic blaquiado",
    ];
    var clasesprim = [
      "descero",
      "desuno",
      "desdos",
      "destes",
      "descua",
      "descin",
      "desseis",
    ];
    var clasedeanimos = [
      "circufue",
      "circuseg",
      this.sufremamon("circucua"),
      "circucin",
    ];
    var anid = [],
      anidos = [];
    iconosa.forEach((e, i) => {
      anid.push(
        <div className={"iconscont " + clasesprim[i]} key={i}>
          <i className={e}></i>
          <i className={iconosb[i] + " fa-rotate-180"}></i>
        </div>
      );
    });
    clasedeanimos.forEach((e, i) => {
      anidos.push(<div className={e + " circos"} key={i}></div>);
    });
    return (
      <div className="renivelar">
        <div className="papadelcirculito realtivo" style={this.dagiritos(1)}>
          <div className="realtivo encierraresalta" style={this.dagiritos(-1)}>
            <div className={this.sufremamon("elresaltador")}></div>
          </div>
          {anid}
          {anidos}
        </div>
      </div>
    );
  };
  dagiritos = (dat) => {
    var med = this.state.girados;
    if (dat === -1) {
      med = this.state.girados - 25.7;
    }
    return { transform: "rotate(" + med * dat + "deg)" };
  };

  handleChange = (e) => {
    if (this.state.ledioclick === false) {
      this.setState({ ledioclick: true });
    }
    var media = parseInt(e.target.value) / 7.5;
    media = parseInt(media) * 25.7;
    this.setState({ [e.target.name]: e.target.value, girados: media });
  };
  traetextos = () => {
    var tit = this.props.eltext(183).split("/");
    var text = this.props.eltext(184).split("/");
    var media = parseInt(this.state.elquemide) / 7.5;
    var elco = "soyelbueno";
    if (media < 7) elco = "soyelmalo";

    return (
      <div className={"textorueda " + elco}>
        <div className="titurieda"> {tit[parseInt(media)]}</div>
        {text[parseInt(media)]}
      </div>
    );
  };
  devuelvemeamichica = () => {
    var media = parseInt(this.state.elquemide) / 7.5;
    var elco = "labarradegiron";
    if (media < 7) elco = "labarradegiro";
    return elco + " realtivo";
  };
  sufremamon = (dat) => {
    var media = parseInt(this.state.elquemide) / 7.5;
    var elco = "";
    if (media < 7) elco = " escureresalta";
    return dat + elco;
  };
  responsivolibro = () => {
    var absolutico = 1;
    if (window.innerWidth < 600) absolutico = window.innerWidth / 600;
    return {
      transform: "scale(" + absolutico + ")",
    };
  };
  muesclientes = () => {
    if (this.state.nusclientes < 50)
      return {
        opacity: "0",
        transform: "rotateY(90deg)",
      };
  };
  elbok = () => {
    if (this.state.elbook < 50)
      return {
        opacity: "0",
        transform: "rotateX(90deg)",
      };
  };
  lepreg = () => {
    if (this.state.laspreg < 50) {
      return {
        opacity: "0",
      };
    }
  };
  traelbok = () => {
    var primer = [],
      second;
    for (let i = 0; i < 6; i++) {
      primer.push(<div className="page" key={i}></div>);
    }
    for (let i = 7; i > 0; i--) {
      second = (
        <div className={"flip flip" + i} key={i}>
          {second}
        </div>
      );
    }
    return (
      <div className="book">
        <div className="gap"></div>
        <div className="pages">{primer}</div>
        <div className="flips">{second}</div>
      </div>
    );
  };
  traelarespuestas = () => {
    var preg = this.props.eltext(188).split("/");
    var resp = this.props.eltext(189).split("/");
    var eltot = [];
    preg.forEach((e, i) => {
      eltot.push(
        <details className="contepreunca" key={i}>
          <summary className="tipreguland">{e}</summary>
          <div className="texrespuesta">{resp[i]}</div>
        </details>
      );
    });
    return eltot;
  };

  efectoburbuja = (sale, seva) => {
    var escala = (this.state.porsubma - sale) / seva + 0.3;
    if (this.state.porsubma < sale || this.state.porsubma > seva + 20) {
      return {
        opacity: "0",
      };
    } else {
      if (escala <= 1) {
        return {
          transform: "scale(" + escala + ")",
        };
      }
    }
  };

  render = () => {
    return [
      <div className="cuerlandin" style={this.stilointerac()} key="cuerp">
        <div className="scrollzonas" style={this.quitaopade()}>
          <span></span>scroll
        </div>
        {this.traetitulo(174, this.state.porproduc)}
        {this.traedivconicono()}
      </div>,

      <div className="concli" key="personaj" style={this.muesclientes()}>
        <div className="titdos"> {this.props.eltext(181)}</div>
        {this.logosclient()}
      </div>,
      <div className="cuerlandino" key="calidad" style={this.muevebolita()}>
        <div className="calidadtitle">{this.props.eltext(182)}</div>
        {this.traetextos()}
        <label htmlFor="elquemide" style={{ opacity: "0" }}>
          gira
        </label>
        <input
          className={this.devuelvemeamichica()}
          id="elquemide"
          type="range"
          name="elquemide"
          min="0"
          max="100"
          value={this.state.elquemide}
          onChange={this.handleChange}
        />
        <div className="lacalidad">
          <div className="textosabajorue">{this.props.eltext(185)}</div>
          <div className="textosabajorue">{this.props.eltext(186)}</div>
        </div>
        {this.traecirculito()}
      </div>,
      <div className="cuerlandina" key="trabajo" style={this.elbok()}>
        <div className="trabajotitle">{this.props.eltext(118)}</div>
        <div style={this.responsivolibro()}>{this.traelbok()}</div>
      </div>,
      <div className="cuerlandinu" key="question" style={this.lepreg()}>
        <div className="calidadtitle">{this.props.eltext(187)}</div>
        {this.traelarespuestas()}
      </div>,
    ];
  };
}
export default Landing;
