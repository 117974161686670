import React, { Component } from "react";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";

import Compedita from "./compedita";

class Result extends Component {
  state = {
    eltotal: 0,
    elconvertido: 0,
    quieresabe: false,
    laexpli: "",
    textdemas: "",
    panta: 0,
    ti: 0,
    ico: "",
    editacam: false,
    enviacot: false,
    acabo: false,
    acaban: false,
    extamensa: "",
    tel: "",
  };
  sacaicono = () => {
    for (var i = 0; i < this.props.loidio.length; i++) {
      if (this.props.loidio[i].identi === this.props.moned) {
        this.setState({ ico: this.props.loidio[i].icon });
      }
    }
  };

  componentDidMount = () => {
    this.sacaicono();
    this.verifitusario();
    setTimeout(() => {
      this.midelapantalla(true);
    }, 1500);
  };
  verifitusario = () => {
    var loquetrae = localStorage.getItem("tisuario");
    if (loquetrae !== null) {
      this.setState({ ti: loquetrae });
    }
  };

  midelapantalla = (valo = false) => {
    if (this.state.ti !== 0) {
      var media = this.props.total[1];
      if (
        media === 3 ||
        media === 4 ||
        media === 5 ||
        media === 7 ||
        media === 8
      ) {
        this.sacatotal();
        if (media === 4) this.setState({ textdemas: this.props.eltexto(62) });
        if (media === 5) this.setState({ textdemas: this.props.eltexto(108) });
        if (media === 7) this.setState({ textdemas: this.props.eltexto(109) });
        if (media === 8) this.setState({ textdemas: this.props.eltexto(110) });
        this.setState({ panta: media });
        this.muestraenvio();
      }
    } else if (valo) {
      setTimeout(() => {
        this.midelapantalla();
      }, 4000);
    }
  };

  desactivanose = () => {
    document.body.style.overflow = "visible";
    this.setState({ quieresabe: false });
  };

  activanose = () => {
    document.body.style.overflow = "hidden";
    this.setState({ quieresabe: true });
    var obj = ["texto"];
    if (obj === undefined) {
      this.setState({ laexpli: "error" });
    } else {
      var segmed = obj[0];
      this.setState({ laexpli: Object.values(segmed)[2] });
    }
  };
  format = (datos) => {
    var num = datos;
    if (!isNaN(num)) {
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      num = num.split("").reverse().join("").replace(/^\./, "");
      return num;
    }
  };

  indicapromoneda = () => {
    var valor = this.state.eltotal;
    for (var i = 0; i < this.props.loidio.length; i++) {
      if (this.props.loidio[i].identi === this.props.moned) {
        var total = valor * this.props.loidio[i].vale;
        total = total.toFixed(2);
        this.setState({ elconvertido: total });
      }
    }
  };

  conalamo = (vat) => {
    for (var i = 0; i < this.props.loidio.length; i++) {
      if (this.props.loidio[i].identi === this.props.moned) {
        var total = vat * this.props.loidio[i].vale;
        total = total.toFixed(2);
        return parseFloat(total);
      }
    }
  };
  sacatotal = () => {
    axios
      .post("https://api.desenfrenadonetworks.com/cuestion", this.props.total)
      .then((response) => {
        this.setState({ eltotal: response.data[0].total });
        this.indicapromoneda();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  elestiinte = () => {
    return {
      top: this.state.quieresabe ? "0" : "200%",
    };
  };
  stilointerac = () => {
    return {
      opacity: this.props.vacamb ? "0" : null,
    };
  };
  reinicia = () => {
    localStorage.removeItem("respues");
    localStorage.removeItem("pregunt");
    localStorage.removeItem("tisuario");
    localStorage.removeItem("debeguarlaco");
    window.location.replace("");
  };
  eltipodeusuario = (val) => {
    this.setState({ ti: val });
    setTimeout(() => {
      this.midelapantalla();
      this.sacatotal();
    }, 1500);
    localStorage.setItem("tisuario", val);
  };
  traeloquedebe = () => {
    if (String(this.state.panta) !== 0) {
      var contlement = ["primplan", "seguplan", "tercplan"];
      var titul = [
        this.props.eltexto(73),
        this.props.eltexto(74),
        this.props.eltexto(75),
      ];
      var tiemp = [3, 6, 12];
      var time = this.props.eltexto(63);
      var elalm = [];
      var fina = this.props.eltexto(78);
      var lasmen = [],
        valure = [];
      var valores, elcon;

      if (String(this.state.panta) === "3") {
        elcon = [
          this.props.eltexto(81),
          this.props.eltexto(82),
          this.props.eltexto(83),
        ];
      } else if (String(this.state.panta) === "4") {
        elcon = [
          this.props.eltexto(65),
          this.props.eltexto(66),
          this.props.eltexto(67),
        ];
      } else if (String(this.state.panta) === "5") {
        elcon = [
          this.props.eltexto(85),
          this.props.eltexto(86),
          this.props.eltexto(87),
        ];
      } else if (String(this.state.panta) === "7") {
        elcon = [this.props.eltexto(88)];
        tiemp = [""];
        time = "";
        titul = [this.props.eltexto(75)];
      } else if (String(this.state.panta) === "8") {
        elcon = [
          this.props.eltexto(89),
          this.props.eltexto(90),
          this.props.eltexto(91),
        ];
      }

      if (String(this.state.ti) === "1") {
        valores = this.state.elconvertido * 0.1;
        valores = valores.toFixed(2);
        valores = parseFloat(valores);
        titul = [this.props.eltexto(75)];
        time = this.props.eltexto(77);
        tiemp = [1];
        valure = [valores];
        fina = "";
        if (String(this.state.panta) === "3") {
          contlement = ["seguplan"];
          elcon = [this.props.eltexto(80)];
        } else if (String(this.state.panta) === "4") {
          contlement = ["tercplan"];
          elcon = [this.props.eltexto(79)];
        } else if (String(this.state.panta) === "5") {
          contlement = ["primplan"];
          elcon = [this.props.eltexto(79)];
        } else if (String(this.state.panta) === "7") {
          contlement = ["seguplan"];
          elcon = [this.props.eltexto(88)];
        } else if (String(this.state.panta) === "8") {
          contlement = ["tercplan"];
          elcon = [this.props.eltexto(79)];
        }
      } else if (String(this.state.ti) === "2") {
        valores = this.state.elconvertido * 0.4;
        valores = valores.toFixed(2);
        valores = parseFloat(valores);
        if (String(this.state.panta) === "3") {
          lasmen = [
            this.conalamo(260000),
            this.conalamo(230000),
            this.conalamo(200000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(400000)).toFixed(2),
            (valores + this.conalamo(800000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "4") {
          lasmen = [
            this.conalamo(180000),
            this.conalamo(150000),
            this.conalamo(120000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(300000)).toFixed(2),
            (valores + this.conalamo(700000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "5") {
          lasmen = [
            this.conalamo(190000),
            this.conalamo(160000),
            this.conalamo(130000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(400000)).toFixed(2),
            (valores + this.conalamo(800000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "7") {
          contlement = ["primplan"];
          lasmen = [""];
          valure = [valores];
        } else if (String(this.state.panta) === "8") {
          lasmen = [
            this.conalamo(170000),
            this.conalamo(150000),
            this.conalamo(120000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(300000)).toFixed(2),
            (valores + this.conalamo(700000)).toFixed(2),
          ];
        }
      } else if (String(this.state.ti) === "3") {
        valores = this.state.elconvertido * 0.6;
        valores = valores.toFixed(2);
        valores = parseFloat(valores);
        if (String(this.state.panta) === "3") {
          lasmen = [
            this.conalamo(300000),
            this.conalamo(260000),
            this.conalamo(220000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(500000)).toFixed(2),
            (valores + this.conalamo(1000000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "4") {
          lasmen = [
            this.conalamo(220000),
            this.conalamo(180000),
            this.conalamo(140000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(400000)).toFixed(2),
            (valores + this.conalamo(900000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "5") {
          lasmen = [
            this.conalamo(230000),
            this.conalamo(190000),
            this.conalamo(150000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(500000)).toFixed(2),
            (valores + this.conalamo(1000000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "7") {
          contlement = ["tercplan"];
          lasmen = [""];
          valure = [valores];
        } else if (String(this.state.panta) === "8") {
          lasmen = [
            this.conalamo(220000),
            this.conalamo(180000),
            this.conalamo(140000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(500000)).toFixed(2),
            (valores + this.conalamo(1000000)).toFixed(2),
          ];
        }
      } else if (String(this.state.ti) === "4") {
        valores = this.state.elconvertido * 0.8;
        valores = valores.toFixed(2);
        valores = parseFloat(valores);
        if (String(this.state.panta) === "3") {
          lasmen = [
            this.conalamo(350000),
            this.conalamo(300000),
            this.conalamo(240000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(600000)).toFixed(2),
            (valores + this.conalamo(12000000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "4") {
          lasmen = [
            this.conalamo(250000),
            this.conalamo(200000),
            this.conalamo(150000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(500000)).toFixed(2),
            (valores + this.conalamo(1000000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "5") {
          lasmen = [
            this.conalamo(260000),
            this.conalamo(210000),
            this.conalamo(160000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(600000)).toFixed(2),
            (valores + this.conalamo(1100000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "7") {
          contlement = ["seguplan"];
          lasmen = [""];
          valure = [valores];
        } else if (String(this.state.panta) === "8") {
          lasmen = [
            this.conalamo(250000),
            this.conalamo(200000),
            this.conalamo(150000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(500000)).toFixed(2),
            (valores + this.conalamo(1000000)).toFixed(2),
          ];
        }
      } else if (String(this.state.ti) === "5") {
        valores = this.state.elconvertido * 1;
        valores = valores.toFixed(2);
        valores = parseFloat(valores);
        if (String(this.state.panta) === "3") {
          lasmen = [
            this.conalamo(380000),
            this.conalamo(330000),
            this.conalamo(280000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(700000)).toFixed(2),
            (valores + this.conalamo(1400000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "4") {
          lasmen = [
            this.conalamo(280000),
            this.conalamo(220000),
            this.conalamo(160000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(600000)).toFixed(2),
            (valores + this.conalamo(1200000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "5") {
          lasmen = [
            this.conalamo(290000),
            this.conalamo(230000),
            this.conalamo(170000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(700000)).toFixed(2),
            (valores + this.conalamo(1300000)).toFixed(2),
          ];
        } else if (String(this.state.panta) === "7") {
          contlement = ["tercplan"];
          lasmen = [""];
          valure = [valores];
        } else if (String(this.state.panta) === "8") {
          lasmen = [
            this.conalamo(280000),
            this.conalamo(220000),
            this.conalamo(160000),
          ];
          valure = [
            valores,
            (valores + this.conalamo(600000)).toFixed(2),
            (valores + this.conalamo(1200000)).toFixed(2),
          ];
        }
      }
      for (var i = 0; i < contlement.length; i++) {
        var clases = "isaplan " + contlement[i] + " estadentro";
        elalm.push(
          <div className={clases} key={i}>
            <div className="tituloplan">{titul[i]}</div>
            {this.props.eltexto(64)}
            <div className="tituloplan">
              {" "}
              {tiemp[i]} {time}
            </div>
            <div dangerouslySetInnerHTML={{ __html: elcon[i] }}></div>
            {this.state.ico} {this.format(lasmen[i])} {this.props.moned}
            <div dangerouslySetInnerHTML={{ __html: fina }}></div>
            <div className="tituloplan">
              {" "}
              {this.state.ico} {this.format(valure[i])} {this.props.moned}
            </div>
          </div>
        );
      }
      return <div className="elcontplan">{elalm}</div>;
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  muestralostisuarios = () => {
    var loselementos = [];
    var dat = [
      { cla: "fal fa-graduation-cap masgrande", ti: this.props.eltexto(93) },
      { cla: "fal fa-brain masgrande", ti: this.props.eltexto(94) },
      { cla: "fal fa-cube masgrande", ti: this.props.eltexto(95) },
      { cla: "fal fa-building masgrande", ti: this.props.eltexto(96) },
      { cla: "fal fa-city masgrande", ti: this.props.eltexto(97) },
    ];
    for (let i = 0; i < dat.length; i++) {
      var temporal = (
        <div
          className="cuerpopre"
          onClick={() => this.eltipodeusuario(i + 1)}
          key={i}
        >
          <div className="laimagen">
            <i className={dat[i].cla}></i>
          </div>
          <div className="eltexto">{dat[i].ti}</div>
        </div>
      );
      loselementos.push(temporal);
    }
    return loselementos;
  };

  cuandosuba = (e) => {
    e.preventDefault();
    var envi;
    if (this.props.loguea.user === "soyinvitado") {
      envi = {
        ident: "",
        valor: this.props.total,
        mensaje: this.state.extamensa,
        tele: this.state.tel,
        tot: this.state.eltotal,
      };
    } else {
      envi = {
        ident: this.props.loguea,
        valor: this.props.total,
        mensaje: this.state.extamensa,
        tot: this.state.eltotal,
      };
    }
    axios
      .post("https://api.desenfrenadonetworks.com/perfilus/reenvco", envi)
      .then((response) => {
        this.setState({ acabo: true });
        setTimeout(() => {
          localStorage.removeItem("respues");
          localStorage.removeItem("pregunt");
          localStorage.removeItem("tisuario");
          localStorage.removeItem("debeguarlaco");
          window.location.replace("");
        }, 4000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  elestiinteractuando = () => {
    return {
      top: this.state.editacam ? "0" : "130%",
    };
  };
  elestiinteractua = () => {
    return {
      top: this.state.enviacot ? "0" : "130%",
    };
  };
  ele = () => {
    return {
      top: this.state.acabo ? "0" : "130%",
    };
  };
  ala = () => {
    return {
      top: this.state.acaban ? "0" : "130%",
    };
  };
  muestrajustes = () => {
    this.setState({ editacam: true });
    document.body.style.overflow = "hidden";
  };
  quitajustes = () => {
    this.setState({ editacam: false });
    document.body.style.overflow = "visible";
    document.body.style.overflowX = "hidden";
  };

  muestraenvio = () => {
    this.setState({ enviacot: true });
    document.body.style.overflow = "hidden";
  };
  quitaenvio = () => {
    this.setState({ enviacot: false });
    document.body.style.overflow = "visible";
    document.body.style.overflowX = "hidden";
  };
  verilog = () => {
    if (
      this.props.loguea.user === "soyinvitado" ||
      this.props.loguea.user === ""
    ) {
      return (
        <input
          type="number"
          className="ilinput"
          name="tel"
          onChange={this.handleChange}
          value={this.state.tel}
          placeholder={this.props.eltexto(41)}
          required
          min="7"
        />
      );
    }
  };
  guardadosa = () => {
    if (
      this.props.loguea.user === "soyinvitado" ||
      this.props.loguea.user === ""
    ) {
      localStorage.setItem("debeguarlaco", true);
      window.location.replace("/profile");
    } else {
      var guarquien = {
        user: this.props.loguea.user,
        idea: this.props.loguea.caca,
        coti: this.props.total,
      };
      axios
        .post(
          "https://api.desenfrenadonetworks.com/perfilus/guardlalo",
          guarquien
        )
        .then((response) => {
          this.setState({ acaban: true });
          setTimeout(() => {
            localStorage.removeItem("respues");
            localStorage.removeItem("pregunt");
            localStorage.removeItem("tisuario");
            localStorage.removeItem("debeguarlaco");
            window.location.replace("");
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render = () => {
    if (this.state.panta === 0) {
      return (
        <div className="cuerpo" style={this.stilointerac()}>
          <div className="titulopre">{this.props.eltexto(92)}</div>
          {this.muestralostisuarios()}
        </div>
      );
    } else {
      return (
        <div className="cuerpo" style={this.stilointerac()}>
          <div className="titulopres">
            <div className="estitul">{this.props.eltexto(14)}</div>
            <div className="textoderesult">{this.props.eltexto(15)}</div>
            {this.traeloquedebe()}

            <div className="textoderesultin">{this.props.eltexto(68)}</div>
            <div className="contienedivs">
              <div className="eliconopregu " onClick={this.activanose}>
                <img src="iconos/mas.svg" alt="duda" />
              </div>
              <div className="eliconopregu " onClick={this.muestrajustes}>
                <img src="iconos/confi.svg" alt="duda" />
              </div>
            </div>

            <div className="elcontplan">
              <div className="elboton estadentro" onClick={this.guardadosa}>
                {this.props.eltexto(51)}
              </div>
              <div className="elboton estadentro" onClick={this.muestraenvio}>
                {this.props.eltexto(49)}
              </div>
              <div className="elboton estadentro" onClick={this.reinicia}>
                {this.props.eltexto(69)}
              </div>
            </div>
          </div>

          <div
            className="flotanterest"
            style={this.elestiinte()}
            onClick={this.desactivanose}
          >
            <div className="xinservible">
              <i className="fas fa-times"></i>
            </div>
            <div className="contflotat">
              <div className="tituloplan">{this.props.eltexto(61)}</div>
              <div
                className="textoderesult"
                dangerouslySetInnerHTML={{ __html: this.state.textdemas }}
              ></div>
            </div>
          </div>

          <div
            className="flotanteexplic quitahe"
            style={this.elestiinteractuando()}
          >
            <div className="yosoyelclick" onClick={this.quitajustes}></div>
            <div className="xinservible" onClick={this.quitajustes}>
              <i className="fas fa-times"></i>
            </div>
            <Compedita
              eltexto={this.props.eltexto}
              cambid={this.props.cambid}
              activate={this.props.activate}
              verifactivo={this.props.verifactivo}
              loidio={this.props.loidio}
              langua={this.props.langua}
              cambmoned={this.props.cambmoned}
              moned={this.props.moned}
              anch={this.props.anch}
              reini={true}
            />
          </div>

          <div
            className="flotanteexplic quitahe"
            style={this.elestiinteractua()}
          >
            <div className="yosoyelclick" onClick={this.quitaenvio}></div>
            <div className="contflota minhe">
              <div className="xinservible" onClick={this.quitaenvio}>
                <i className="fas fa-times"></i>
              </div>
              <div className="textnormal realtivo">
                <div className="eltitulon"> {this.props.eltexto(98)}</div>

                <form onSubmit={this.cuandosuba} className="preguncuest">
                  <TextareaAutosize
                    type="text"
                    className="ilinput latextarea "
                    minRows={2}
                    maxLength={600}
                    name="extamensa"
                    onChange={this.handleChange}
                    value={this.state.extamensa}
                    placeholder={this.props.eltexto(57)}
                  />
                  {this.verilog()}
                  <button type="submit" className="elboton">
                    {this.props.eltexto(49)}
                  </button>
                  <div className="elboton subale" onClick={this.quitaenvio}>
                    {this.props.eltexto(190)}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="flotanteexpli quitahe menfinal"
            style={this.ele()}
            dangerouslySetInnerHTML={{ __html: this.props.eltexto(99) }}
          ></div>

          <div
            className="flotanteexpli quitahe menfinal"
            style={this.ala()}
            dangerouslySetInnerHTML={{ __html: this.props.eltexto(100) }}
          ></div>
        </div>
      );
    }
  };
}
export default Result;
