import React, { Component } from "react";
import axios from "axios";

import Elperfil from "./elperfil";

class Verisuario extends Component {
  state = {
    esigua: false,
    pant: 1,
    recico: { user: "", pas: "" },
    resp: "",
    veriacti: false,
    recire: {
      tuser: "",
      pasa: "",
      names: "",
      corre: "",
      telefono: "",
      pasdo: "",
    },
    deshabco: "",
    courrier: "",
    entro: false,
    limit: true,
    carga: false,
    cambiint: false,
  };

  abretex = (id) => {
    var obj = this.props.content[id - 1];
    if (obj === undefined) {
      return "cargando...";
    } else {
      var valores = Object.values(obj);
      return valores[1];
    }
  };
  componentDidUpdate = () => {
    if (this.props.loguea.length !== 0) {
      if (this.state.limit) {
        if (this.props.loguea.caca === "soyinvitado") {
          this.setState({ entro: false });
        } else {
          this.setState({ entro: true });
        }
        this.setState({ limit: false });
      }
    }
  };
  veripant = () => {
    if (this.props.loguea.caca === "soyinvitado") {
      this.setState({ entro: 1 });
    } else {
      this.setState({ entro: 1 });
    }
  };
  cambiarpant = (val) => {
    this.setState({ cambiint: true });
    setTimeout(() => {
      this.setState({ pant: val, veriacti: false });
      setTimeout(() => {
        this.setState({ cambiint: false });
      }, 500);
    }, 500);
  };
  cuandosubaco = (e) => {
    this.setState({ carga: true });
    e.preventDefault();
    var obj = { courrier: this.state.courrier, idio: this.props.langua };
    axios
      .post("https://api.desenfrenadonetworks.com/login/email", obj)
      .then((response) => {
        if (response.data === "Email enviado") {
          this.cambiarpant(5);
        } else {
          this.setState({ resp: this.abretex(59) });
          this.setState({ veriacti: true });
        }
        this.setState({ carga: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  cuandosuba = (e) => {
    this.setState({ carga: true });
    e.preventDefault();
    axios
      .post(
        "https://api.desenfrenadonetworks.com/login/acceso",
        this.state.recico
      )
      .then((response) => {
        if (
          response.data === "MAL USER O CONTRA" ||
          response.data === "error al enviar datos"
        ) {
          this.setState({ resp: this.abretex(9) });
          this.setState({ veriacti: true });
          localStorage.setItem("userio", "soyinvitado");
          localStorage.setItem("idea", "soyinvitado");
          localStorage.setItem("name", "soyinvitado");
          this.props.actilaz("soyinvitado", "soyinvitado", "soyinvitado");
        } else {
          localStorage.setItem("userio", this.state.recico.user);
          localStorage.setItem("idea", response.data[0].hash);
          localStorage.setItem("name", response.data[0].nom);
          this.props.actilaz(
            this.state.recico.user,
            response.data[0].hash,
            response.data[0].nom
          );
          if (this.props.gulacoti) {
            var guarquien = {
              user: this.state.recico.user,
              idea: response.data[0].hash,
              coti: this.props.total,
            };
            axios
              .post(
                "https://api.desenfrenadonetworks.com/perfilus/guardlalo",
                guarquien
              )
              .then((response) => {
                localStorage.removeItem("respues");
                localStorage.removeItem("pregunt");
                localStorage.removeItem("tisuario");
                localStorage.removeItem("debeguarlaco");
                window.location.replace("");
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            window.location.replace("");
          }
        }
        this.setState({ carga: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  cuandosubare = (e) => {
    this.setState({ carga: true });
    e.preventDefault();
    if (this.state.recire.pasa === this.state.recire.pasdo) {
      axios
        .post(
          "https://api.desenfrenadonetworks.com/login/register",
          this.state.recire
        )
        .then((response) => {
          if (
            response.data === "ya existe el usuario" ||
            response.data === "error al enviar datos" ||
            response.data === "Error saving user"
          ) {
            this.setState({ resp: this.abretex(20) });
            this.setState({ veriacti: true });
            localStorage.setItem("userio", "soyinvitado");
            localStorage.setItem("idea", "soyinvitado");
            localStorage.setItem("name", "soyinvitado");
            this.props.actilaz("soyinvitado", "soyinvitado", "soyinvitado");
          } else {
            localStorage.setItem("userio", this.state.recire.tuser);
            localStorage.setItem("idea", response.data[0].hash);
            localStorage.setItem("name", this.state.recire.names);
            this.props.actilaz(
              this.state.recire.tuser,
              response.data[0].hash,
              this.state.recire.names
            );
            window.location.replace("");
          }
          this.setState({ carga: false });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ resp: this.abretex(19) });
      this.setState({ veriacti: true });
      this.setState({ carga: false });
    }
  };
  stilointeractiv = () => {
    return {
      opacity: this.props.vacamb ? 0 : 1,
      filter: this.state.cambiint ? "blur(200px)" : "blur(0)",
    };
  };
  stiloint = () => {
    return {
      display: this.state.veriacti ? "flex" : "none",
    };
  };
  cambioinputlo = (e) => {
    if (e.target.name === "user") {
      this.setState({
        recico: { user: e.target.value, pas: this.state.recico.pas },
      });
    } else {
      this.setState({
        recico: { user: this.state.recico.user, pas: e.target.value },
      });
    }
  };

  cambioinputco = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  cambioinputreo = (e) => {
    var tuser, pasa, names, corre, pasdo;
    if (e.target.name === "tuser") {
      tuser = e.target.value.replace(" ", "");
      tuser = tuser.replace(/'/gi, "");
      tuser = tuser.replace(/"/gi, "");
    } else {
      tuser = this.state.recire.tuser;
    }
    if (e.target.name === "pasa") {
      pasa = e.target.value;
    } else {
      pasa = this.state.recire.pasa;
    }
    if (e.target.name === "names") {
      names = e.target.value;
    } else {
      names = this.state.recire.names;
    }
    if (e.target.name === "corre") {
      corre = e.target.value;
    } else {
      corre = this.state.recire.corre;
    }
    if (e.target.name === "pasdo") {
      pasdo = e.target.value;
    } else {
      pasdo = this.state.recire.pasdo;
    }

    this.setState({
      recire: {
        tuser: tuser,
        pasa: pasa,
        names: names,
        corre: corre,
        pasdo: pasdo,
      },
    });
  };
  stacargando = (def) => {
    if (def) {
      return {
        display: this.state.carga ? "none" : "block",
      };
    } else {
      return {
        display: this.state.carga ? "block" : "none",
      };
    }
  };

  render = () => {
    if (this.state.entro) {
      return (
        <div className="cuerpo">
          <div className="titulopres">
            <Elperfil
              eltexto={this.abretex}
              loguea={this.props.loguea}
              langua={this.props.langua}
              cierra={this.props.actilaz}
            />
          </div>
        </div>
      );
    } else {
      if (this.state.pant === 1) {
        return (
          <div className="pregunlogin" style={this.stilointeractiv()}>
            <div className="eltitu">{this.abretex(60)}</div>
            <div className="eltituy">{this.abretex(5)}</div>
            <div className="elboton subale" onClick={() => this.cambiarpant(2)}>
              {this.abretex(8)}
            </div>
            <div className="elboton subale" onClick={() => this.cambiarpant(3)}>
              {this.abretex(10)}
            </div>
          </div>
        );
      } else if (this.state.pant === 2) {
        return (
          <form
            onSubmit={this.cuandosuba}
            className="pregunlogin"
            style={this.stilointeractiv()}
          >
            <div className="eltitu">{this.abretex(8)}</div>
            <div className="error" style={this.stiloint()}>
              {this.state.resp}
            </div>
            <input
              type="text"
              placeholder={this.abretex(6)}
              onChange={this.cambioinputlo}
              value={this.state.recico.user}
              name="user"
              required
              className="ilinput"
            />

            <input
              type="password"
              className="ilinput"
              name="pasa"
              onChange={this.cambioinputlo}
              value={this.state.recico.pas}
              placeholder={this.abretex(7)}
              required
            />

            <div
              className="aumentapaddi dejamepe"
              onClick={() => this.cambiarpant(4)}
            >
              {this.abretex(11)}
            </div>
            <div
              className="aumentapaddi centratex"
              onClick={() => this.cambiarpant(3)}
            >
              {this.abretex(12)}
            </div>
            <button
              type="submit"
              className="elboton"
              disabled={this.state.carga}
            >
              <i
                style={this.stacargando(false)}
                className="fas fa-sync-alt giragira"
              ></i>
              <div style={this.stacargando(true)}>{this.abretex(8)}</div>
            </button>
          </form>
        );
      } else if (this.state.pant === 3) {
        return (
          <form
            onSubmit={this.cuandosubare}
            className="pregunlogin"
            style={this.stilointeractiv()}
          >
            <div className="eltitu">{this.abretex(10)}</div>
            <div className="error" style={this.stiloint()}>
              {this.state.resp}
            </div>

            <input
              type="text"
              className="ilinput"
              name="tuser"
              onChange={this.cambioinputreo}
              value={this.state.recire.tuser}
              placeholder={this.abretex(6)}
              required
              minLength="4"
            />

            <input
              type="text"
              className="ilinput"
              name="names"
              onChange={this.cambioinputreo}
              value={this.state.recire.name}
              placeholder={this.abretex(16)}
              required
            />

            <input
              type="email"
              className="ilinput"
              name="corre"
              onChange={this.cambioinputreo}
              value={this.state.recire.corre}
              placeholder={this.abretex(17)}
              required
            />

            <input
              type="password"
              className="ilinput"
              name="pasa"
              onChange={this.cambioinputreo}
              value={this.state.recire.pasa}
              placeholder={this.abretex(7)}
              required
              title={this.abretex(26)}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            />

            <input
              type="password"
              className="ilinput"
              name="pasdo"
              onChange={this.cambioinputreo}
              value={this.state.recire.pasdo}
              placeholder={this.abretex(18)}
              required
            />

            <div
              className="aumentapaddi centratex"
              onClick={() => this.cambiarpant(2)}
            >
              {this.abretex(13)}
            </div>
            <button
              type="submit"
              className="elboton"
              disabled={this.state.carga}
            >
              <i
                style={this.stacargando(false)}
                className="fas fa-sync-alt giragira"
              ></i>
              <div style={this.stacargando(true)}>{this.abretex(10)}</div>
            </button>
          </form>
        );
      } else if (this.state.pant === 4) {
        return (
          <form
            onSubmit={this.cuandosubaco}
            className="pregunlogin"
            style={this.stilointeractiv()}
          >
            <div className="eltitu">{this.abretex(21)}</div>
            <div className="error" style={this.stiloint()}>
              {this.state.resp}
            </div>
            <input
              type="text"
              placeholder={this.abretex(22)}
              onChange={this.cambioinputco}
              value={this.state.courrier}
              name="courrier"
              required
              className="ilinput"
            />
            <div
              className="aumentapaddi centratex"
              onClick={() => this.cambiarpant(2)}
            >
              {this.abretex(13)}
            </div>
            <button
              type="submit"
              className="elboton"
              disabled={this.state.carga}
            >
              <i
                style={this.stacargando(false)}
                className="fas fa-sync-alt giragira"
              ></i>
              <div style={this.stacargando(true)}>{this.abretex(8)}</div>
            </button>
          </form>
        );
      } else if (this.state.pant === 5) {
        return (
          <div className="pregunlogin" style={this.stilointeractiv()}>
            <div className="eltituy">{this.abretex(29)}</div>
            <div
              type="submit"
              className="elboton subale"
              onClick={() => window.location.replace("")}
            >
              {this.abretex(30)}
            </div>
          </div>
        );
      } else {
        return 0;
      }
    }
  };
}
export default Verisuario;
